import Modal from 'react-modal'
import { tagModalStyles } from './styles'
import { MultiSelect, Option } from 'react-rainbow-components'
import { useEffect, useState } from 'react'
import Button from '../../common/Button'
import useFetch from '../../hooks/useFetch'
import { urlApi } from '../../config'
import LoadingIndicator from '../../common/LoadingIndicator'
import { Brightness1 } from '@mui/icons-material'

const tags = ['answer', 'day', 'outside-contact', 'join', 'innapropiate', 'pet', 'outside-payment']

export default ({ tagModal, setTagModal, channel, handleChangeChannels }) => {
  const [selectedTags, setSelectedTags] = useState(
    channel.tags?.map(tag => {
      return { name: tag, value: tag, label: label(tag) }
    }) || []
  )
  const [saveTags, setSaveTags] = useState(false)

  return (
    <Modal
      style={tagModalStyles}
      isOpen={tagModal}
      onRequestClose={() => setTagModal(false)}
      contentLabel='Etiquetas'
    >
      <div className='flex flex-col gap-6 p-4'>
        <h2 className='text-xl font-bold text-primary'>Etiquetas</h2>
        <MultiSelect
          placeholder={'Selecciona los tags del canal'}
          value={selectedTags}
          onChange={setSelectedTags}
          variant='chip'
          showCheckbox
        >
          {tags.map(tag => (
            <Option
              key={tag}
              value={tag}
              name={tag}
              label={label(tag)}
              icon={<Brightness1 sx={{ color: color(tag) }} />}
            />
          ))}
        </MultiSelect>
        <div className='flex gap-4 items-center w-full justify-end'>
          {saveTags ? (
            <SaveTags
              selectedTags={selectedTags}
              setTagModal={setTagModal}
              setSaveTags={setSaveTags}
              channelId={channel._id}
              handleChangeChannels={handleChangeChannels}
            />
          ) : (
            <>
              <p className='text-lg cursor-pointer' onClick={() => setTagModal(false)}>
                Cancelar
              </p>
              <Button className='w-[20px]' name={'Guardar'} onClick={() => setSaveTags(true)} />
            </>
          )}
        </div>
      </div>
    </Modal>
  )
}

const SaveTags = ({ selectedTags, setTagModal, setSaveTags, channelId, handleChangeChannels }) => {
  const { loading, responseOk } = useFetch(`${urlApi}/admin/channels/tag/${channelId}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ tags: selectedTags.map(tag => tag.value) })
  })

  useEffect(() => {
    if (responseOk) {
      handleChangeChannels({
        _id: channelId,
        tags: selectedTags.map(tag => tag.value)
      })
      setTagModal(false)
      setSaveTags(false)
    } else if (!loading) {
      alert('Error al guardar las etiquetas')
      setSaveTags(false)
    }
  }, [loading])

  return <LoadingIndicator />
}

export const label = tag => {
  switch (tag) {
    case 'answer':
      return 'No tuvo respuesta'
    case 'day':
      return 'Se confundió de día'
    case 'outside-contact':
      return 'Intentó contactarse por fuera'
    case 'join':
      return 'No sabe como proceder'
    case 'innapropiate':
      return 'Conducta inapropiada'
    case 'pet':
      return 'Consultó Mascota'
    case 'outside-payment':
      return 'Pago por fuera de la app'
    default:
      return ''
  }
}

export const color = tag => {
  switch (tag) {
    case 'answer':
      return 'yellow'
    case 'day':
      return 'blue'
    case 'outside-contact':
      return 'red'
    case 'join':
      return 'green'
    case 'innapropiate':
      return 'purple'
    case 'pet':
      return 'brown'
    case 'outside-payment':
      return 'orange'
    default:
      return ''
  }
}
