import useFetch from '../../hooks/useFetch'
import { urlApi } from '../../config'
import Alert from '../Alert'

export default ({ lobby, comment, setSendComment }) => {
  const { loading, data, responseOk } = useFetch(`${urlApi}/admin/lobbies/comment/${lobby._id}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      comment: comment
    })
  })
  if (!loading && responseOk) {
    lobby.Admin = {
      comment: comment
    }
  }

  return (
    <Alert
      visible={true}
      onClose={() => {
        setSendComment(false)
      }}
      hideButton={loading}
    >
      {
        loading ?
          <>Cargando...</>
          :
          !responseOk ?
            <div className='text-red-600 font-extrabold text-2xl'>Error al cargar el comentario</div>
            :
            <div className='text-green-700 font-extrabold text-2xl'>Comentario cargado con éxito</div>
      }
    </Alert>
  )
}
