import { Room, Flag } from '@mui/icons-material'

const Locations = ({ originName, destinationName }) => {
  return (
    <div
      style={{ display: 'flex', textAlign: 'left', flexDirection: 'column', justifyContent: 'space-around' }}
    >
      <div style={{ display: 'flex', gap: '3px' }} >
        <Room fontSize='small' style={{ color: '#c65944', marginTop: '3px' }} />
        <div>{originName}</div>
      </div>
      <div style={{ display: 'flex', gap: '3px' }} >
        <Flag fontSize='small' style={{ color: '#c65944', marginTop: '3px' }} />
        <div>{destinationName}</div>
      </div>
    </div>
  )
}

export default Locations