import { useEffect, useState } from 'react'
import LobbyCard from '../../common/LobbyCard'

const LobbiesList = ({ fields, data, setSelectedLobby, setLength }) => {
  const [lobbies, setLobbies] = useState(data)
  const today = new Date()
  today.setHours(0, 0, 0, 0)
  const late = (lobby) => {
    const date = new Date(lobby.Journey.departure_time)
    return date < today
  }

  useEffect(() => {
    let filteredLobbies = data
    if (fields.filters.includes('active') && fields.filters.includes('company')) {
      filteredLobbies = data.filter(lobby => !late(lobby) && lobby.companions.length > 0)
    } else {
      if (fields.filters.includes('active')) {
        filteredLobbies = data.filter(lobby => !late(lobby))
      } else if (fields.filters.includes('company')) {
        filteredLobbies = data.filter(lobby => lobby.companions.length > 0)
      }
    }
    if (!(fields.nameSearch == '')) {
      filteredLobbies = filteredLobbies.filter(lobby => (
        lobby.Owner_info.first_name.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(fields.nameSearch.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) ||
        lobby._id.includes(fields.nameSearch) ||
        lobby?._old_id?.includes(fields.nameSearch) ||
        lobby?._first_id?.includes(fields.nameSearch)
      ))
    }
    // if (!(fields.localitySearch == '')) {
    //   filteredLobbies = filteredLobbies.filter(lobby => lobby.Journey.origin_name.formatted_address.toUpperCase().includes(fields.localitySearch.toUpperCase()) ||
    //     lobby.Journey.destination_name.formatted_address.toUpperCase().includes(fields.localitySearch.toUpperCase()))
    // }
    if (!(fields.originSearch == '')) {
      filteredLobbies = filteredLobbies.filter(lobby => lobby.Journey.origin_name.formatted_address.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(fields.originSearch.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")))
    }
    if (!(fields.destinationSearch == '')) {
      filteredLobbies = filteredLobbies.filter(lobby => lobby.Journey.destination_name.formatted_address.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(fields.destinationSearch.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")))
    }
    if (Object.keys(fields.dateRange).length != 0) {
      filteredLobbies = filteredLobbies.filter(lobby => {
        let departureTime = lobby.Journey.departure_time
        departureTime = departureTime.substring(0, departureTime.indexOf('T') + 1) + '03:00:00.000Z'
        return (departureTime <= fields.dateRange.endDate.toISOString() &&
          departureTime >= fields.dateRange.startDate.toISOString())
      })
    }
    if (fields.filters.includes('nodocumented')) {
      filteredLobbies = filteredLobbies.filter(lobby => !lobby?.Admin?.comment)
    }
    if (fields.filters.includes('orderByTime')) {
      filteredLobbies = filteredLobbies.sort((a, b) => new Date(a.Journey.departure_time) - new Date(b.Journey.departure_time))
        .sort((a, b) => (Boolean(a._old_id) === Boolean(b._old_id)) ? 0 : a._old_id ? 1 : -1)
    }
    if (fields.lobbyFilter == 'completed') {
      filteredLobbies = filteredLobbies.filter(lobby => lobby.completed)
    } else if (fields.lobbyFilter == 'cancelled') {
      filteredLobbies = filteredLobbies.filter(lobby => lobby.cancelled)
    } else if (fields.lobbyFilter == 'pending') {
      filteredLobbies = filteredLobbies.filter(lobby => !lobby.completed && !lobby.cancelled && lobby.companions.length > 0 && ((new Date(lobby.Journey.departure_time)) < today))
    } else if (fields.lobbyFilter == 'not-cancelled') {
      filteredLobbies = filteredLobbies.filter(lobby => !lobby.cancelled)
    }
    setLobbies(filteredLobbies)
    setLength(filteredLobbies.length)
  }, [fields.filters, fields.nameSearch, fields.originSearch, fields.destinationSearch, fields.dateRange, fields.lobbyFilter])
  return (
    <ol className='overflow-auto h-screen'>
      {
        lobbies.map(lobby =>
          <li>
            <LobbyCard
              lobby={lobby}
              setSelected={setSelectedLobby}
            />
          </li>)
      }
      <li className='text-xl text-center mb-64'>
        FIN
      </li>
    </ol>
  )
}

export default LobbiesList