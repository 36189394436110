
import { urlApi } from '../../config'
import { useEffect, useState } from 'react'
import { Alert } from '@mui/material'
import useFetch from '../../hooks/useFetch'
import { Dots } from 'react-activity'

export default ({ notifications, hidden }) => {
  const [readAllNotifications, setReadAllNotifications] = useState(false)
  if (hidden) return <></>
  return (
    <div
      className='h-2/3 flex flex-col px-3 rounded-lg py-2 bg-gray-400 shadow-xl h-screen w-3/8'
    >
      <h2 className='text-center text-2xl mb-2'>Notificaciones</h2>
      {
        notifications.length == 0 ?
          <>No hay</>
          :
          <div className='overflow-auto h-screen flex flex-col gap-2 w-full'>
            {
              notifications.map(notification => <Notification notification={notification} />
              )
            }
            <h2 className='text-center mt-3 mb-0'>FIN</h2>

            <div
              className='text-blue-700 text-center cursor-pointer mb-32 justify-center mt-2'
              onClick={() => setReadAllNotifications(true)}
            >Leer todo</div>
          </div>
      }
      {readAllNotifications && <ReadAllNotifications setReadAllNotifications={setReadAllNotifications} />}
    </div>
  )
}

const Notification = ({ notification }) => {
  const [readNotification, setReadNotification] = useState(false)
  let date = new Date((new Date(notification.created)).getTime() - 3 * 60 * 60 * 1000).toISOString()
  date = date.substr(8, 2) + '/' + date.substr(5, 2) + ' - ' + date.substr(11, 5)
  return (
    <div className='bg-orange-200 rounded-xl p-4' >
      <div className='flex'>
        {
          notification.active ?
            <div
              className='bg-red-500 w-8 h-7 rounded-2xl cursor-pointer mr-2'
              onClick={() => setReadNotification(true)}
            >
            </div>
            :
            <></>
        }
        {
          notification.type == 'joined' ?
            <div className='w-full'>
              <div className='flex'>
                <p className='text-red-500 text-xl mr-1'>!!!</p>
                Un usuario se UNIÓ a la lobby {notification.meta_data.lobby_id}
              </div>
              <div className='flex justify-end text-gray-500'>
                {date}
              </div>
            </div>
            : notification.type == 'companion' ?
              <div className='w-full'>
                Un usuario se AGENDÓ como acompañante
                <div className='flex justify-end text-gray-500'>
                  {date}
                </div>
              </div>
              : notification.type == 'lobby' ?
                <div className='w-full'>
                  Un usuario CREÓ una lobby
                  <div className='flex justify-end text-gray-500'>
                    {date}
                  </div>
                </div>
                : notification.type == 'lobby_edit' ?
                  <div className='w-full'>
                    Se EDITÓ la lobby {notification.meta_data.lobby_id}
                    <div className='flex justify-end text-gray-500'>
                      {date}
                    </div>
                  </div>
                  : notification.type == 'lobby_leave' ?
                    <div className='w-full'>
                      El usuario {notification.meta_data.user_id} ABANDONÓ la lobby {notification.meta_data.lobby_id}
                      <div className='flex justify-end text-gray-500'>
                        {date}
                      </div>
                    </div>
                    : notification.type == 'lobby_cancel' ?
                      <div className='w-full'>
                        Se CANCELÓ la lobby {notification.meta_data.lobby_id}
                        <div className='flex justify-end text-gray-500'>
                          {date}
                        </div>
                      </div>
                      : notification.type == 'delete_account' || notification.type == 'delete-account' ?
                        <div className='w-full'>
                          Se solucitó eliminar la cuenta del usuario {notification.meta_data.user_id}
                          <div className='flex justify-end text-gray-500'>
                            {date}
                          </div>
                        </div>
                        :
                        <></>
        }
      </div>
      {
        readNotification ?
          <ReadNotification
            setReadNotification={setReadNotification}
            notification={notification}
          />
          :
          <></>
      }
    </div>
  )
}

const ReadNotification = ({ setReadNotification, notification }) => {
  const [visible, setVisible] = useState(true)
  const { loading, data } = useFetch(`${urlApi}/admin/notifications/${notification._id}`, {
    method: 'PATCH',
  })

  useEffect(() => {
    if (!loading && data.ok != false) {
      setVisible(false)
      setReadNotification(false)
      notification.active = false
    }
  }, [loading])
  return (
    <Alert
      visible={visible}
    >
      {
        loading ?
          <Dots />
          : data.ok == false ?
            <div className='text-red-600 font-extrabold text-2xl'>Error al actualizar notification</div>
            :
            <></>
      }
    </Alert>
  )
}

const ReadAllNotifications = ({ setReadAllNotifications }) => {
  const [visible, setVisible] = useState(true)
  const { loading, data } = useFetch(`${urlApi}/admin/notifications/all`, {
    method: 'PATCH',
  })

  useEffect(() => {
    if (!loading && data.ok != false) {
      if (data) {
        setTimeout(() => window.location.reload(), 1500)
      }
      // setVisible(false)
      // setReadAllNotifications(false)
    }
  }, [loading])
  return (
    <Alert
      visible={visible}
    >
      {
        loading ?
          <Dots />
          : data.ok == false ?
            <div className='text-red-600 font-extrabold text-2xl'>Error al leer todas las notificationes</div>
            :
            <div className='text-green-600 font-extrabold text-2xl'>Notificaciones leidas correctamente</div>
      }
    </Alert>
  )
}