import { Avatar, TextField } from '@mui/material'
import { useEffect, useState } from 'react'
import useFetch from '../../hooks/useFetch'
import { urlApi } from '../../config'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import { ArrowBackIosNew, Close } from '@mui/icons-material'
import Modal from 'react-modal'
import LobbyInfo from '../../common/LobbyInfo'
import LobbyCard from '../../common/LobbyCard'
import Alert from '../../common/Alert'

const UserSearch = ({ navigate }) => {
  const [text, setText] = useState('')
  const [search, setSearch] = useState(false)

  useEffect(() => {
    const handleEnter = e => {
      if (e.key === 'Enter') {
        setSearch(false)
        setTimeout(() => {
          setSearch(true)
        }, 100)
      }
    }
    document.addEventListener('keydown', handleEnter)
    return () => {
      document.removeEventListener('keydown', handleEnter)
    }
  }, [text])

  return (
    <div className='flex flex-col bg-gray-200 rounded-xl px-7 py-5 overflow-auto mx-6 h-screen pb-20'>
      <div className='flex flex-row text-center justify-between items-center'>
        <TextField
          label='Usuario'
          variant='outlined'
          value={text}
          onChange={e => {
            let val = e.target.value
            if (Number(val.substr(1, 1))) {
              setText(val.replaceAll(' ', ''))
            } else {
              setText(val)
            }
          }}
          className='w-1/2'
        />
        <button
          className='bg-gray-300 rounded-md py-1 px-3 shadow-2xl mb-2 bg-[#FF0000] text-[#fff] w-1/6 h-10'
          onClick={() => setSearch(false)}
        >
          Cancelar
        </button>
        <button
          className='bg-gray-300 rounded-md py-1 px-3 shadow-2xl mb-2 bg-[#d65944] text-[#fff] w-1/6 h-10'
          onClick={() => {
            setSearch(false)
            setTimeout(() => {
              setSearch(true)
            }, 100)
          }}
        >
          Buscar
        </button>
      </div>
      {search && <UsersRequest text={text} />}
    </div>
  )
}

const dias = ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado']
const meses = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre'
]

const UsersRequest = ({ text }) => {
  const { loading, data } = useFetch(`${urlApi}/admin/users/${text}`)
  const [userInfo, setUserInfo] = useState()

  return (
    <div>
      {loading ? (
        <>Cargando...</>
      ) : userInfo ? (
        <UserInfo userInfo={userInfo} setUserInfo={setUserInfo} />
      ) : data.status == 404 || data.status == 500 ? (
        <div className='flex flex-col justify-center items-center mt-10'>
          <p className='text-2xl text-center'>Error en la busqueda</p>
        </div>
      ) : data.length == 0 ? (
        <div className='flex flex-col justify-center items-center mt-10'>
          <p className='text-2xl text-center'>No se encontró ningún usuario</p>
        </div>
      ) : (
        <div>
          {data.map((user, index) => (
            <div
              className='flex flex-row justify-between items-center cursor-pointer bg-gray-300 w-full my-2 py-2 px-3 rounded-xl text-sm'
              onClick={() => setUserInfo(user)}
            >
              <div className='flex flex-row items-center'>
                <Avatar src={user.picture} sx={{ width: 56, height: 56, marginRight: 2 }} />
                <div className='flex flex-col'>
                  <p>
                    {user?.user_metadata?.first_name} {user?.user_metadata?.last_name}
                  </p>
                  <p>{user?.user_id}</p>
                </div>
              </div>
              <p>{user?.user_metadata?.phone_number}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

const UserInfo = ({ userInfo, setUserInfo }) => {
  const createdDateTime = new Date(new Date(userInfo.created_at).getTime() - 3 * 60 * 60 * 1000)
  const lastDateTimeInUruguay = new Date(
    new Date(userInfo.last_login).getTime() - 3 * 60 * 60 * 1000
  ).toISOString()
  const createdTime = createdDateTime
    .toISOString()
    .substr(createdDateTime.toISOString().indexOf('T') + 1, 5)
  const createdDate =
    dias[createdDateTime.getDay()] +
    ' ' +
    createdDateTime.getDate() +
    ' de ' +
    meses[createdDateTime.getMonth()]
  const lastTime = lastDateTimeInUruguay.substr(lastDateTimeInUruguay.indexOf('T') + 1, 5)
  const lastDate =
    dias[new Date(userInfo.last_login).getDay()] +
    ' ' +
    new Date(userInfo.last_login).getDate() +
    ' de ' +
    meses[new Date(userInfo.last_login).getMonth()]

  const [userLobbiesVisible, setUserLobbiesVisible] = useState(false)
  const [verifyUser, setVerifyUser] = useState(false)
  const [declineVerificationAlert, setDeclineVerificationAlert] = useState(false)
  const [declineVerification, setDeclineVerification] = useState(false)

  useEffect(() => {
    userInfo.user_metadata.first_name = userInfo.user_metadata.first_name.trim()
    if (userInfo.user_metadata.phone_number.charAt(0) != '+') {
      userInfo.user_metadata.phone_number =
        '+598' +
        userInfo.user_metadata.phone_number.substring(1, userInfo.user_metadata.phone_number.length)
    }
  }, [])

  //Calculate age with data.user_metadata.birthdate
  const age = () => {
    const birthdate = new Date(userInfo.user_metadata.birthdate)
    const today = new Date()
    let age = today.getFullYear() - birthdate.getFullYear()
    const month = today.getMonth() - birthdate.getMonth()
    if (month < 0 || (month === 0 && today.getDate() < birthdate.getDate())) {
      age--
    }
    return age
  }

  return (
    <div>
      <ArrowBackIosNew className='cursor-pointer mt-3' onClick={() => setUserInfo()} />
      <div className='flex flex-col justify-center mb-3'>
        <div className='flex justify-center'>
          <img className='rounded-full mt-3' src={userInfo.picture} width='95px' height='95px' />
        </div>
        {userInfo.user_metadata.gender != 'Hombre' && userInfo.user_metadata.gender != 'Mujer' && (
          <div className='flex m-auto text-2xl my-2 text-purple-900'>
            <p className='font-bold mr-1'>GENERO: {userInfo.user_metadata.gender}</p>
          </div>
        )}
        <div className='flex m-auto'>
          <p className='font-bold mr-1'>Nombre:</p> {userInfo.user_metadata.first_name}
        </div>
        <div className='flex m-auto'>
          <p className='font-bold mr-1'>Apellido:</p> {userInfo.user_metadata.last_name}
        </div>
        <div
          className='flex flex-row m-auto cursor-pointer'
          onClick={() =>
            window.open(
              `https://api.whatsapp.com/send/?phone=${userInfo.user_metadata.phone_number}&text=%20`
            )
          }
        >
          <p className='font-bold mr-1'>Tel: {userInfo.user_metadata.phone_number}</p>
          <WhatsAppIcon
            className='cursor-pointer text-green-600'
            onClick={() =>
              window.open(
                `https://api.whatsapp.com/send/?phone=${userInfo.user_metadata.phone_number}&text=%20`
              )
            }
          />
        </div>
        <div className='flex m-auto'>
          <p className='font-bold mr-1'>Email: </p> {userInfo.email}
        </div>
        {userInfo?.user_metadata?.preferences && (
          <div className='flex m-auto'>
            <p className='font-bold mr-1'>Preferencias:</p>{' '}
            {userInfo.user_metadata.preferences.map(x => x + ' | ')}
          </div>
        )}
        {userInfo?.user_metadata?.biography && (
          <div className='w-full flex justify-center'>
            <div className='w-[400px] flex m-auto'>
              <p className='font-bold'>Biografía:</p>
              {userInfo.user_metadata.biography}
            </div>
          </div>
        )}
        <div className='flex m-auto'>
          <p className='font-bold mr-1'>Edad: </p>
          {age()}
        </div>
        {userInfo?.user_metadata?.verified && (
          <div className='flex m-auto'>
            <p className='font-bold mr-1'>Usuario Verificado</p>
          </div>
        )}
        <div className='flex m-auto'>
          <p className='font-bold mr-1'>Registrado:</p> {createdDate} {createdTime}
        </div>
        <div className='flex m-auto'>
          <p className='font-bold mr-1'>Ultima vez:</p> {lastDate} {lastTime}
        </div>
        <div className='flex m-auto'>
          <p className='font-bold mr-1'>ID:</p> {userInfo.user_id}
        </div>
        <button
          className='bg-[#d65944] text-white font-bold py-2 px-4 rounded-xl mx-auto mt-3'
          onClick={() => setUserLobbiesVisible(true)}
        >
          Viajes
        </button>
        {userInfo.user_metadata.verified === 'pending' && (
          <p className='text-center text-primary mt-10 rounded-lg'>
            Usuario pendiente de verificación
          </p>
        )}
        {(!userInfo.user_metadata.verified || userInfo.user_metadata.verified === 'pending') && (
          <button
            className='bg-light_coral text-white font-bold py-2 px-4 rounded-xl mx-auto mt-6'
            onClick={() => setVerifyUser(true)}
          >
            Verificar
          </button>
        )}
        {userInfo.user_metadata.verified === 'pending' && (
          <button
            className='bg-error text-white font-bold py-2 px-4 rounded-xl mx-auto mt-4'
            onClick={() => setDeclineVerificationAlert(true)}
          >
            Rechazar Verificación
          </button>
        )}
        {userLobbiesVisible && (
          <UserLobbies
            userLobbiesVisible={userLobbiesVisible}
            setUserLobbiesVisible={setUserLobbiesVisible}
            userId={userInfo.user_id}
          />
        )}
        {verifyUser && <VerifyUser setVerifyUser={setVerifyUser} userId={userInfo.user_id} />}
        {declineVerification && (
          <DeclineVerification
            setDeclineVerification={setDeclineVerification}
            userId={userInfo.user_id}
          />
        )}
        {declineVerificationAlert && (
          <Alert
            onSubmit={() => setDeclineVerification(true)}
            onClose={() => setDeclineVerificationAlert(false)}
            visible={declineVerificationAlert}
          >
            <p>¿Seguro que querés rechazar la verificación del usuario?</p>
          </Alert>
        )}
      </div>
    </div>
  )
}

const UserLobbies = ({ userLobbiesVisible, setUserLobbiesVisible, userId }) => {
  const { loading, data } = useFetch(`${urlApi}/admin/users/lobbies/${userId}`)
  const [selectedLobby, setSelectedLobby] = useState({})

  const customStyles = {
    content: {
      borderRadius: '1.5rem',
      marginTop: 'auto',
      marginBottom: 'auto',
      paddingLeft: '1.6rem',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
    },
    overlay: {
      zIndex: 90,
      backgroundColor: 'rgba(0,0,0,0)'
    }
  }

  const sortLobbies = lobbies => {
    return lobbies.sort((a, b) => {
      const dateA = a.Journey.departure_time
      const dateB = b.Journey.departure_time
      return dateA > dateB ? -1 : 1
    })
  }

  return (
    <>
      <Modal
        isOpen={userLobbiesVisible}
        onRequestClose={() => setUserLobbiesVisible(false)}
        style={customStyles}
      >
        <div className='flex flex-col h-full'>
          <Close onClick={() => setUserLobbiesVisible(false)} />
          {loading ? (
            <div className='flex justify-center'>
              <p>Cargando...</p>
            </div>
          ) : data.length > 0 ? (
            <ol className='overflow-auto h-screen'>
              {sortLobbies(data).map(lobby => (
                <li>
                  <LobbyCard lobby={lobby} setSelected={setSelectedLobby} />
                </li>
              ))}
              <li className='text-xl text-center'>FIN</li>
            </ol>
          ) : (
            <div className='flex justify-center'>
              <p>No hay viajes</p>
            </div>
          )}
        </div>
      </Modal>
      {Object.keys(selectedLobby).length > 0 && (
        <LobbyInfo lobby={selectedLobby} setSelected={setSelectedLobby} />
      )}
    </>
  )
}

const VerifyUser = ({ setVerifyUser, userId }) => {
  const { loading, responseOk } = useFetch(`${urlApi}/admin/users/verify/${userId}`, {
    method: 'POST'
  })

  useEffect(() => {
    if (responseOk) {
      alert('Usuario verificado')
      setVerifyUser(false)
    } else if (!loading) {
      alert('Error al verificar')
      setVerifyUser(false)
    }
  }, [loading])
}

const DeclineVerification = ({ setDeclineVerification, userId }) => {
  const { loading, responseOk } = useFetch(`${urlApi}/admin/users/decline-verification/${userId}`, {
    method: 'POST'
  })

  useEffect(() => {
    if (responseOk) {
      alert('Usuario rechazado')
      setDeclineVerification(false)
    } else if (!loading) {
      alert('Error al rechazar')
      setDeclineVerification(false)
    }
  }, [loading])
}

export default UserSearch
