import { useState, useEffect } from 'react'
import auth0Config from '../config/Auth0'
import { useAuth0 } from '@auth0/auth0-react'

const useFetch = (url, fetchInfo, loop) => {
  const { getAccessTokenSilently } = useAuth0()
  const [loading, setLoading] = useState(true)
  const [responseOk, setResponseOk] = useState(false)
  const [data, setData] = useState([])

  const fetchData = async token => {
    if (fetchInfo?.headers) {
      fetchInfo.headers.authorization = `Bearer ${token}`
    } else if (!fetchInfo) {
      fetchInfo = { headers: { authorization: `Bearer ${token}` } }
    } else {
      fetchInfo.headers = { authorization: `Bearer ${token}` }
    }
    fetch(url, fetchInfo)
      .then(async response => {
        if (response.ok) {
          setResponseOk(true)
          return await response.json()
        } else {
          return response
        }
      })
      .then(data => {
        setData(data)
        if (loop) setTimeout(() => fetchData(token), 5 * 60 * 1000)
        setLoading(false)
      })
      .catch(e => {
        setData(e)
        setLoading(false)
      })
  }

  useEffect(() => {
    getAccessTokenSilently({
      audience: auth0Config.audience
    })
      .then(token => fetchData(token))
      .catch(e => {
        console.log(e)
        setLoading(false)
      })
  }, [])

  return { loading, data, responseOk }
}

export default useFetch
