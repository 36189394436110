import { Room, Flag } from '@mui/icons-material'

const Locations = ({ originName, destinationName }) => {
  return (
    <div
      style={{ display:'flex', textAlign:'left', flexDirection:'column', justifyContent:'space-around' }}
    >
      <div>
        <div style={{ display:'flex', gap:'2' }} >
          <Room fontSize='small' style={{ color: '#c65944' }}/>
          <div>{originName}</div>
        </div>
        <div style={{ display:'flex', gap:'2' }} >
          <Flag fontSize='small' style={{ color: '#c65944' }}/>
          <div>{destinationName}</div>
        </div>
      </div>
    </div>
  )
}

export default Locations