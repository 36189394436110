import styled from 'styled-components';

export const CardContainer = styled('div')`
    display: flex; 
    padding-bottom: 0.75rem; 
    padding-top: 1.25rem; 
    padding-right: 1.5rem; 
    padding-left: 1.5rem; 
    margin-left: 1.25rem;
    margin-right: 1.25rem; 
    margin-bottom: 0.5rem;
    font-size: 1.125rem;
    line-height: 1.75rem; 
    justify-content: space-between; 
    border-radius: 0.75rem; 
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    cursor: pointer;
`;