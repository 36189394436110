import { useState } from 'react'
import { addDays, endOfDay } from 'date-fns'
import { DateRangePicker } from 'mui-daterange-picker'

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ setDateRange }) => {
  const [selectedRange, setSelectedRange] = useState('')
  const [openDatePicker, setOpenDatePicker] = useState(false)
  const [customRange, setCustomRange] = useState('')

  const handleRangeSelection = (range) => {
    setSelectedRange(range)
    if (range == selectedRange && range != 'custom') {
      setSelectedRange('')
      setDateRange(false)
      return
    }

    if (range == 'custom') {
      setOpenDatePicker(true)
    } else {
      setOpenDatePicker(false)

      const today = new Date()
      today.setHours(0, 0, 0, 0)
      let start, end

      if (range === '7d') {
        start = addDays(today, -6)
        end = endOfDay(today)
      } else if (range === '30d') {
        start = addDays(today, -29)
        end = endOfDay(today)
      } else if (range === '3m') {
        start = addDays(today, -89)
        end = endOfDay(today)
      }

      setDateRange({
        start: start,
        end: end
      })
    }
  }

  const handleCustomRangeSelection = (range) => {
    setCustomRange(range)
    setDateRange({
      start: range.startDate,
      end: endOfDay(range.endDate)
    })
  }

  return (
    <div className='flex justify-between items-center p-4 py-1 bg-white shadow-md rounded-lg gap-2'>
      <button
        className={`px-4 py-3 text-sm rounded-md ${selectedRange === '7d' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
        onClick={() => handleRangeSelection('7d')}
      >
        Últimos 7 días
      </button>
      <button
        className={`px-4 py-2 text-sm rounded-md ${selectedRange === '30d' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
        onClick={() => handleRangeSelection('30d')}
      >
        Últimos 30 días
      </button>
      <button
        className={`px-4 py-2 text-sm rounded-md ${selectedRange === '3m' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
        onClick={() => handleRangeSelection('3m')}
      >
        Últimos 3 meses
      </button>
      <button
        className={`px-4 py-2 text-sm rounded-md ${selectedRange === 'custom' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
        onClick={() => handleRangeSelection('custom')}
      >
        Rango personalizado
      </button>
      {
        selectedRange === 'custom' && customRange && (
          <span className='text-sm text-gray-500'>
            {`${customRange.startDate.toLocaleDateString()} - ${customRange.endDate.toLocaleDateString()}`}
          </span>
        )
      }
      {
        selectedRange && <button
          className='bg-red-500 rounded-md py-1 shadow-2xl'
          style={{ width: 31 }}
          onClick={() => {
            setSelectedRange('')
            setDateRange(false)
          }}
        >
          x
        </button>
      }

      {openDatePicker && (
        <DateRangePicker
          open={openDatePicker}
          toggle={() => setOpenDatePicker(!openDatePicker)}
          onChange={(range) => { handleCustomRangeSelection(range); setOpenDatePicker(!openDatePicker) }}
        />
      )}
    </div>
  )
}