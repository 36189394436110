import useFetch from '../../hooks/useFetch'
import { useState } from 'react'
import { urlApi } from '../../config'
import Alert from '../Alert'

export default ({ lobby }) => {
  const [alert, setAlert] = useState(true)
  const { loading, responseOk } = useFetch(`${urlApi}/admin/lobbies/uncancel/${lobby._id}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    }
  })

  return (
    <Alert
      visible={alert}
      onClose={() => {
        if (!responseOk) {
          setAlert(false)
        } else {
          window.location.reload()
        }
      }}
      hideButton={loading}
    >
      {
        loading ?
          <>Cargando...</>
          :
          responseOk ?
            <div className='text-green-700 font-extrabold text-2xl'>Lobby restaurada con exito</div>
            :
            <div className='text-red-600 font-extrabold text-2xl'>Error al restaurar la lobby</div>
      }
    </Alert>
  )
}
