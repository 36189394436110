import { useState } from 'react'
import { urlApi } from '../../config'
import Locations from './Locations'
import { CalendarMonth } from '@mui/icons-material'
import { CardContainer } from './styles'
import auth0Config from '../../config/Auth0'
import { useAuth0 } from '@auth0/auth0-react'

const dias = ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado']
const meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo',
  'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre',
  'Noviembre', 'Diciembre']

const Card = ({ companion, setSelected, recommendatedLobby }) => {
  let recommending = false
  const { getAccessTokenSilently, } = useAuth0()
  const [recommendingText, setRecommendingText] = useState('Recomendar >')

  const recommendLobby = async (lobby) => {
    recommending = true
    setRecommendingText('Recomendando...')
    const token = await getAccessTokenSilently({
      audience: auth0Config.audience,
    })
    await fetch(`${urlApi}/admin/users/id/${companion.User_info.id}`, {
      headers: { authorization: 'Bearer ' + token }
    }).then(res => res.json())
      .then(user => {
        if (user.user_metadata.phone_number.charAt(0) != '+') {
          user.user_metadata.phone_number = '+598' + user.user_metadata.phone_number.substring(1, user.user_metadata.phone_number.length)
        }
        let originText = lobby.Journey.origin_name.locality
        let selectedOriginName = originText
        let selectedPrice = lobby.price
        let selectedTime = lobby.Journey.departure_time
        lobby.Journey.stops.forEach(stop => {
          if (companion.origin_name.includes(stop.location_name)) {
            selectedOriginName = stop.location_name
            const price = ((parseInt(lobby.Journey.distance) - parseInt(stop.distance)) * (lobby.price / parseInt(lobby.Journey.distance))) * ((100 + 6) / 100)
            const roundedPrice = Math.round(price / 10) * 10
            selectedPrice = roundedPrice
            selectedTime = stop.time
          }
        })

        let destinationText = lobby.Journey.destination_name.locality
        let selectedDestinationName = destinationText

        lobby.Journey.stops.forEach(stop => {
          if (companion.destination_name.includes(stop.location_name)) {
            selectedDestinationName = stop.location_name
            const price = (parseInt(stop.distance) * (lobby.price / parseInt(lobby.Journey.distance))) * ((100 + 6) / 100) // 6 Es el STOP FEE
            const roundedPrice = Math.round(price / 10) * 10
            if (originText == selectedOriginName) {
              selectedPrice = roundedPrice
            }
          }
        })

        const departureDate = new Date(new Date(selectedTime).getTime() + 3 * 60 * 60 * 1000)
        let formattedDate = 'el ' + dias[departureDate.getDay()] + ' ' + departureDate.getDate() + ' de ' + meses[departureDate.getMonth()]
        if ((departureDate.getDate() == (new Date()).getDate()) && (departureDate.getMonth() == (new Date()).getMonth())) {
          formattedDate = 'el+día+de+hoy'
        } else if ((departureDate.getDate() == (new Date()).getDate() + 1) && (departureDate.getMonth() == (new Date()).getMonth())) {
          formattedDate = 'el+día+de+mañana'
        }

        if ((destinationText != selectedDestinationName) && (originText != selectedOriginName)) {
          selectedPrice = '(DEFINIR PRECIO)'
        }

        if (originText == selectedOriginName) {
          selectedOriginName = 'viaja+de+' + lobby.Journey.origin_name.locality
        } else {
          selectedOriginName = 'pasa+por+' + selectedOriginName + '+y+viaja'
        }
        if (destinationText == selectedDestinationName) {
          destinationText = lobby.Journey.destination_name.locality
        } else {
          destinationText = destinationText
        }

        const formattedTime = selectedTime.substr(selectedTime.indexOf('T') + 1, 5)
        window.open(`https://api.whatsapp.com/send/?phone=${user.user_metadata.phone_number}
                &text=Hola+${user.user_metadata.first_name}%2C%0D%0AHay+un+conductor+que+${selectedOriginName}+a+${selectedDestinationName}+${formattedDate}+a+las+${formattedTime}.+%0D%0APodés+reservar+este+viaje+en+nuestra+App+o+ingresando+a+este+link:+https://app.viatik.com/join/${lobby._id}?utm_source=admin&utm_medium=whatsapp&utm_campaign=matches+🚗💨`)
      })
      .catch(err => {
        setRecommendingText('ERROR')
        recommending = false
      })
    setRecommendingText('Recomendar >')
    recommending = false
  }
  return (
    <>
      <CardContainer
        onClick={() => {
          if (!recommending) setSelected(companion)
        }}
      >
        <Locations
          originName={companion.origin_name}
          destinationName={companion.destination_name}
        />
        <Info
          name={companion.User_info.name}
          time={companion.departure_time}
        />
        {
          recommendatedLobby &&
          <div
            className='flex flex-col justify-center h-full px-5 rounded-lg bg-orange-700 cursor-pointer text-white shadow-lg'
            onClick={() => recommendLobby(recommendatedLobby, companion.User_info.id)}
          >
            <p>{recommendingText}</p>
          </div>
        }
      </CardContainer>
    </>
  )
}

const Info = ({ name, time }) => {
  let timeToShow = time.substr(time.indexOf('-') + 4, 2) + '/' + time.substr(time.indexOf('-') + 1, 2)
  if (time.includes(',')) {
    const secondTime = time.substr(time.indexOf(',') + 1, time.length - time.indexOf(','))
    timeToShow = timeToShow + ' - ' + secondTime.substr(secondTime.indexOf('-') + 4, 2) + '/' + secondTime.substr(secondTime.indexOf('-') + 1, 2)
  }
  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'end' }} >
      <div style={{ display: 'flex', gap: '1' }} >
        <p style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          {timeToShow}
        </p>
        <CalendarMonth fontSize='medium' style={{ color: '#c65944' }} />
      </div>
      <p>{name}</p>
    </div>
  )
}


export default Card