import React from "react"
import { useState, useEffect } from "react"
import { DataGrid } from "@mui/x-data-grid"
import { useAuth0 } from "@auth0/auth0-react"
import auth0Config from '../../config/Auth0'
import { urlApi } from "../../config"
import { DateRangePicker } from "mui-daterange-picker"

const DepartmentTable = ({ navigate }) => {
  const { getAccessTokenSilently } = useAuth0()
  const [rows, setRows] = useState([])
  const [openDatePicker, setOpenDatePicker] = useState(false)
  const [dateRange, setDateRange] = useState({})
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])

  useEffect(() => {
    if (!loading) setRows(data.map((department, index) => ({ id: index, ...department })))
  }, [data, loading])

  useEffect(() => {
    setLoading(true)
    const startDate = dateRange?.startDate?.toISOString()
    let endDate = dateRange?.endDate?.toISOString()
    //if start and end are equal, add one day to endDate
    if (endDate && startDate == endDate) {
      endDate = new Date(endDate)
      endDate.setDate(endDate.getDate() + 1)
      endDate = endDate.toISOString()
    }
    let params = ''

    if (endDate && startDate) {
      params = `?min_time=${startDate}&max_time=${endDate}`
    }
    getAccessTokenSilently({
      audience: auth0Config.audience,
    }).then(token => {
      fetch(`${urlApi}/admin/statistics/departments${params}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      }).then(res => res.json())
        .then(data => {
          setData(data)
          setLoading(false)
        })
    })
  }, [dateRange])

  const columns = [
    { field: 'department', headerName: 'Departamento', width: 130 },
    { field: 'completedTrips', headerName: 'VC', width: 50 },
    { field: 'conversionRate', headerName: 'CR', width: 70 },
    { field: 'cancelRate', headerName: 'CAR (Cancel Rate)', width: 70 },
    { field: 'leaveRate', headerName: 'LER (Leave Rate)', width: 70 },
    { field: 'supply', headerName: 'Supply', width: 70 },
    { field: 'demand', headerName: 'Demand', width: 70 },
    { field: 'occupancy', headerName: 'Occupancy', width: 80 },
    { field: 'tpd', headerName: 'TPD', width: 80 },
    { field: 'tpc', headerName: 'TPC', width: 80 },
    { field: 'transactioned', headerName: 'Transaccioned $', width: 130 },
    { field: 'averageTicket', headerName: 'Average Ticket $', width: 130 },
    { field: 'supplyDemandBalance', headerName: 'SD Balance', width: 130 }
  ]

  return (
    <div className='flex justify-center'>
      <div className='h-screen flex flex-col items-center'>
        <div className='text-center'>
          <button
            className='bg-gray-300 rounded-md py-1 px-3 shadow-2xl mb-2'
            onClick={() => navigate('Statistics')}
          >
            Estadísticas
          </button>
        </div>

        <div className='text-center font-extrabold pb-2 text-2xl'>Estadísticas por Departamento</div>
        <div>
          <button
            className='bg-gray-300 rounded-md py-1 px-3 shadow-2xl'
            onClick={() => setOpenDatePicker(!openDatePicker)}
          >
            Rango de fecha
          </button>
          <button
            disabled={Object.keys(dateRange).length == 0}
            className='bg-red-500 disabled:bg-red-200 rounded-md py-1 px-4 shadow-2xl'
            style={{ width: '8%' }}
            onClick={() => setDateRange({})}
          >
            x
          </button>
          <DateRangePicker
            open={openDatePicker}
            toggle={() => setOpenDatePicker(!openDatePicker)}
            onChange={(range) => { setDateRange(range); setOpenDatePicker(!openDatePicker) }}
          />
        </div>
        {
          loading ?
            <>Cargando...</>
            :
            <DataGrid
              className='w-fit mb-20 mt-4'
              columns={columns}
              rows={rows}
              pageSize={5}
              rowsPerPageOptions={[5]}
              autoPageSize
              disableSelectionOnClick
            />
        }
      </div>
    </div>
  )
}

export default DepartmentTable
