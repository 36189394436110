import { useEffect, useState } from 'react'
import useFetch from '../../hooks/useFetch'
import { urlApi } from '../../config'
import Alert from '../Alert'
import { TextField } from '@mui/material'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'

const dias = ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado']
const meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo',
  'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre',
  'Noviembre', 'Diciembre']

export default ({ userId, type, lobby }) => {
  const [wppMessages, setWppMessages] = useState(false)
  const { loading, data } = useFetch(`${urlApi}/admin/users/id/${userId}`)

  const [createdTime, setCreatedTime] = useState('')
  const [createdDate, setCreatedDate] = useState('')
  const [lastTime, setLastTime] = useState('')
  const [lastDate, setLastDate] = useState('')

  useEffect(() => {
    if (!loading) {
      const createdDateTime = new Date((new Date(data.created_at)).getTime() - 3 * 60 * 60 * 1000)
      setCreatedTime(createdDateTime.toISOString().substr(createdDateTime.toISOString().indexOf('T') + 1, 5))
      setCreatedDate(dias[createdDateTime.getDay()] + ' ' + createdDateTime.getDate() + ' de ' + meses[createdDateTime.getMonth()])
      const lastDateTimeInUruguay = new Date((new Date(data.last_login)).getTime() - 3 * 60 * 60 * 1000).toISOString()
      setLastTime(lastDateTimeInUruguay.substr(lastDateTimeInUruguay.indexOf('T') + 1, 5))
      setLastDate(dias[(new Date(data.last_login)).getDay()] + ' ' + (new Date(data.last_login)).getDate() + ' de ' + meses[(new Date(data.last_login)).getMonth()])
      //trim user first name
      data.user_metadata.first_name = data.user_metadata.first_name.trim()
      if (data.user_metadata.phone_number.charAt(0) != '+') {
        data.user_metadata.phone_number = '+598' + data.user_metadata.phone_number.substring(1, data.user_metadata.phone_number.length)
      }
    }
  }, [loading])
  //Calculate age with data.user_metadata.birthdate
  const age = () => {
    const birthdate = new Date(data.user_metadata.birthdate)
    const today = new Date()
    let age = today.getFullYear() - birthdate.getFullYear()
    const month = today.getMonth() - birthdate.getMonth()
    if (month < 0 || (month === 0 && today.getDate() < birthdate.getDate())) {
      age--
    }
    return age
  }
  return (
    <div
      className='h-ful w-full'
    >
      {
        loading ?
          <>Cargando...</>
          :
          <>
            <div className='h-ful w-full'>
              <div className='flex flex-col justify-center mb-3'>
                <div className='flex justify-center'>
                  <img
                    className='rounded-full mt-3'
                    src={data.picture}
                    width='95px'
                    height='95px'
                  />
                </div>
                {(data.user_metadata.gender != 'Hombre' && data.user_metadata.gender != 'Mujer') &&
                  <div className='flex m-auto text-2xl my-2 text-purple-900'><p className='font-bold mr-1'>GENERO: {data.user_metadata.gender}</p></div>}
                <div className='flex m-auto'><p className='font-bold mr-1'>Nombre:</p> {data.user_metadata.first_name}</div>
                <div className='flex m-auto'><p className='font-bold mr-1'>Apellido:</p> {data.user_metadata.last_name}</div>
                <div clasName='flex m-auto'>
                  <p className='font-bold mr-1'>Tel: <a
                    className='text-blue-600 cursor-pointer'
                    onClick={() => setWppMessages(true)}
                  >{data.user_metadata.phone_number}
                  </a>
                    <WhatsAppIcon
                      className='cursor-pointer text-green-600'
                      onClick={() => window.open(`https://api.whatsapp.com/send/?phone=${data.user_metadata.phone_number}&text=%20`)}
                    /></p>
                </div>
                <div className='flex m-auto'><p className='font-bold mr-1'>Email: </p> {data.email}</div>
                {data?.user_metadata?.preferences && <div className='flex m-auto'><p className='font-bold mr-1'>Preferencias:</p> {data.user_metadata.preferences.map(x => x + ' | ')}</div>}
                {data?.user_metadata?.biography && <div className='w-full flex justify-center'><div className='w-[400px] flex m-auto'><p className='font-bold'>Biografía:</p>{data.user_metadata.biography}</div></div>}
                <div className='flex m-auto'><p className='font-bold mr-1'>Edad: </p>{age()}</div>
                <div className='flex m-auto'><p className='font-bold mr-1'>Registrado:</p> {createdDate} {createdTime}</div>
                <div className='flex m-auto'><p className='font-bold mr-1'>Ultima vez:</p> {lastDate} {lastTime}</div>
                <div className='flex m-auto'><p className='font-bold mr-1'>ID:</p> {data.user_id}</div>
              </div>
            </div>
            <Alert
              visible={wppMessages}
              onClose={() => setWppMessages(false)}
            >
              {
                type == 'owner' ?
                  <OwnerPressets
                    userData={data}
                    lobby={lobby}
                  />
                  : type == 'companion' ?
                    <CompanionPressets
                      lobby={lobby}
                      userData={data}
                    />
                    :
                    <LobbyCompanionPressets
                      lobby={lobby}
                      userData={data}
                    />
              }
            </Alert>
          </>
      }
    </div>
  )
}

const OwnerPressets = ({ userData, lobby }) => {
  const departureDate = new Date(lobby.Journey.departure_time)
  let formattedDate = 'el ' + dias[departureDate.getDay()] + ' ' + departureDate.getDate() + ' de ' + meses[departureDate.getMonth()]
  if ((departureDate.getDate() == (new Date()).getDate()) && (departureDate.getMonth() == (new Date()).getMonth())) {
    formattedDate = 'hoy'
  } else if ((departureDate.getDate() == (new Date()).getDate() - 1) && (departureDate.getMonth() == (new Date()).getMonth())) {
    formattedDate = 'ayer'
  }
  const formattedCompanions = lobby.companions.map(x => x.first_name).join(', ')
  return (
    <div className='h-full mt-40'>
      <div
        className='bg-gray-400 rounded-xl shadow-xl cursor-pointer p-5 mb-4 text-left'
        onClick={() => window.open(`https://api.whatsapp.com/send/?phone=${userData.user_metadata.phone_number}
        &text=Hola+${userData.user_metadata.first_name}%21+%0D%0AQuer%C3%ADamos+confirmar+si+sali%C3%B3+tu+viaje+${formattedDate}+con+${formattedCompanions}.+Esperamos+tu+respuesta%2C+saludos.`)}
      >
        Hola {userData.user_metadata.first_name}!
        <br />
        Queríamos confirmar si salió tu viaje {formattedDate} con {formattedCompanions}. Esperamos tu respuesta, saludos!
      </div>
      <div
        className='bg-gray-400 rounded-xl shadow-xl cursor-pointer p-5 mb-4 text-left'
        onClick={() => window.open(`https://api.whatsapp.com/send/?phone=${userData.user_metadata.phone_number}
                &text=Hola+${userData.user_metadata.first_name}%2C%0D%0ATe+conseguimos+un+acompa%C3%B1ante+para+tu+viaje+de+${lobby.Journey.origin_name.locality}+a+${lobby.Journey.destination_name.locality}%2C+${formattedDate}.%0D%0ACreamos+un+grupo+de+WhatsApp+para+que+puedan+estar+en+contacto+antes+del+viaje.+Pod%C3%A9s+unirte+mediante+este+link.`)}
      >
        Hola {userData.user_metadata.first_name},
        <br />
        Te conseguimos un acompañante para tu viaje de {lobby.Journey.origin_name.locality} a {lobby.Journey.destination_name.locality}, {formattedDate}.
        <br />
        Creamos un grupo de WhatsApp para que puedan estar en contacto antes del viaje. Podés unirte mediante este link: <a className='text-red-500'>(hacer grupo de wpp)</a>
      </div>
    </div>
  )
}

const CompanionPressets = ({ userData, lobby }) => {
  const [lobbyId, setLobbyId] = useState('')
  const [proposedLobby, setProposedLobby] = useState(false)
  return (
    <div className='h-full mt-40'>
      <div
        className='bg-gray-400 rounded-xl shadow-xl cursor-pointer p-5 mb-4 text-left'
        onClick={() => window.open(`https://api.whatsapp.com/send/?phone=${userData.user_metadata.phone_number}
                &text=Hola+${userData.user_metadata.first_name}%2C%0D%0ATu+solicitud+de+viaje+fue+ingresada+correctamente.+%0D%0AEn+caso+de+conseguirte+un+viaje+te+avisamos.`)}
      >
        Hola {userData.user_metadata.first_name},
        <br />
        Tu solicitud de viaje fue ingresada correctamente.
        En caso de conseguirte un viaje te avisamos 😃
      </div>
      {
        !proposedLobby ?
          <div
            className='flex justify-center bg-gray-400 rounded-xl shadow-xl p-5 mb-4 text-left gap-3'
          >
            <h2 className='flex flex-col justify-center'>ID de lobby: </h2>
            <TextField
              size='small'
              label='Lobby ID'
              onChange={evt => setLobbyId(evt.target.value)}
            />
            <button
              className='px-4 bg-gray-500 shadow-xl rounded-xl'
              onClick={() => setProposedLobby(true)}
            >
              Proponer lobby
            </button>
          </div>
          :
          <ProposeLobby
            userData={userData}
            lobbyId={lobbyId}
          />

      }
    </div>
  )
}

const ProposeLobby = ({ userData, lobbyId }) => {
  const [formattedDate, setFormattedDate] = useState('')
  const [formattedTime, setFormattedTime] = useState('')
  const { loading, data } = useFetch(`${urlApi}/lobbies/id/${lobbyId}`)

  useEffect(() => {
    if (!loading) {
      const departureDate = new Date(data.Journey.departure_time)
      setFormattedDate('el ' + dias[departureDate.getDay()] + ' ' + departureDate.getDate() + ' de ' + meses[departureDate.getMonth()])
      if ((departureDate.getDate() == (new Date()).getDate()) && (departureDate.getMonth() == (new Date()).getMonth())) {
        setFormattedDate('hoy')
      } else if ((departureDate.getDate() == (new Date()).getDate() + 1) && (departureDate.getMonth() == (new Date()).getMonth())) {
        setFormattedDate('mañana')
      }
      setFormattedTime(data.Journey.departure_time.substr(data.Journey.departure_time.indexOf('T') + 1, 5))
      const searchDataString = JSON.stringify({
        origin: {
          lat: data.Journey.origin.coordinates[0],
          lng: data.Journey.origin.coordinates[1]
        },
        destination: {
          lat: data.Journey.destination.coordinates[0],
          lng: data.Journey.destination.coordinates[1]
        },
        originName: data.Journey.origin_name,
        destinationName: data.Journey.destination_name,
        selectedDate: data.Journey.departure_time,
        data: 1,
        seats: 1,
      })
    }
  }, [loading])

  return (
    <>
      {
        loading ?
          <>Cargando...</>
          :
          <div
            className='bg-gray-400 rounded-xl shadow-xl cursor-pointer p-5 mb-4 text-left'
            onClick={() => window.open(`https://api.whatsapp.com/send/?phone=${userData.user_metadata.phone_number}
                &text=Hola+${userData.user_metadata.first_name}%2C%0D%0AHay+un+conductor+que+viaja+de+${data.Journey.origin_name.locality}+a+${data.Journey.destination_name.locality}%2C+${formattedDate}+a+las+${formattedTime}.+El+costo+del+viaje+es+$${data.price}.%0D%0APodés+reservar+este+viaje+en+nuestra+App+o+ingresando+a+este+link:+https://app.viatik.com/join/${lobbyId}?utm_source=admin&utm_medium=whatsapp&utm_campaign=matches`)}
          >
            Hola {userData.user_metadata.first_name},
            <br />
            Hay un conductor que viaja desde {data.Journey.origin_name.locality} a {data.Journey.destination_name.locality}, {formattedDate} a las {formattedTime}. El costo del viaje es ${data.price}.
            <br />
            Podés reservar este viaje en nuestra App o ingresando a este link: https://app.viatik.com/join/{lobbyId}?utm_source=admin&utm_medium=whatsapp&utm_campaign=matches
          </div>
      }
    </>
  )
}

const LobbyCompanionPressets = ({ userData, lobby }) => {
  const departureDate = new Date(lobby.Journey.departure_time)
  let formattedDate = 'el ' + dias[departureDate.getDay()] + ' ' + departureDate.getDate() + ' de ' + meses[departureDate.getMonth()]
  let formattedTime = lobby.Journey.departure_time.substr(lobby.Journey.departure_time.indexOf('T') + 1, 5)
  if ((departureDate.getDate() == (new Date()).getDate()) && (departureDate.getMonth() == (new Date()).getMonth())) {
    formattedDate = 'hoy'
  } else if ((departureDate.getDate() == (new Date()).getDate() + 1) && (departureDate.getMonth() == (new Date()).getMonth())) {
    formattedDate = 'mañana'
  }
  return (
    <div
      className='bg-gray-400 rounded-xl shadow-xl cursor-pointer p-5 mb-4 text-left'
      onClick={() => window.open(`https://api.whatsapp.com/send/?phone=${userData.user_metadata.phone_number}
                &text=Hola+${userData.user_metadata.first_name}%2C%0D%0ATe+has+unido+correctamente+al+viaje+de+${lobby.Owner_info.first_name}%2C+desde+${lobby.Journey.origin_name.locality}+a+${lobby.Journey.destination_name.locality}%2C+${formattedDate}+a+las+${formattedTime}.%0D%0ACreamos+un+grupo+de+WhatsApp+para+que+puedan+estar+en+contacto+antes+del+viaje.+Pod%C3%A9s+unirte+mediante+este+link.`)}
    >
      Hola {userData.user_metadata.first_name},
      Te has unido correctamente al viaje de {lobby.Owner_info.first_name}, desde {lobby.Journey.origin_name.locality} a {lobby.Journey.destination_name.locality}, {formattedDate} a las {formattedTime}.
      <br />
      Creamos un grupo de WhatsApp para que puedan estar en contacto antes del viaje. Podés unirte mediante este link: <a className='text-red-500'>(primero hablar con el dueño de la lobby y luego crear el grupo de wpp)</a>
    </div>
  )
}
