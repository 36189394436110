import { Avatar } from '@mui/material'
import LoadingIndicator from '../../common/LoadingIndicator'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'

export default ({
  owner,
  sender,
  message,
  firstFromSender,
  lastFromSender,
  handleDeleteMessage
}) => {
  const time = new Date(message.date).toLocaleTimeString('es-UY', {
    hour: '2-digit',
    minute: '2-digit'
  })

  const handleDeleteClick = () => {
    if (window.confirm('¿Estás seguro que deseas eliminar este mensaje?')) {
      handleDeleteMessage(message)
    }
  }

  return (
    <div
      className={`flex gap-2 items-end ${
        owner ? 'flex-row-reverse md:ml-10' : 'flex-row md:mr-10'
      } ${lastFromSender && 'mb-2'}`}
    >
      <Avatar
        src={sender?.picture}
        alt={sender?.name[0]}
        sx={[{ width: 35, height: 35 }, !lastFromSender && { visibility: 'hidden' }]}
      />
      <div className={bubbleClassName(owner, lastFromSender)}>
        {firstFromSender && <p className='text-base'>{sender?.name}</p>}
        <div className='flex flex-row items-end gap-4'>
          {formatText(message.text)}
          <p className={`text-xs font-thin ${owner ? 'text-white' : 'text-gray'}`}>{time}</p>
        </div>
      </div>
      <div className='h-full flex items-center justify-center'>
        {message.not_sent && <LoadingIndicator size={12} />}
      </div>
      <div
        className='h-full flex items-center justify-center cursor-pointer'
        onClick={handleDeleteClick}
      >
        <FontAwesomeIcon icon={faTrash} color='gray' />
      </div>
    </div>
  )
}

const bubbleClassName = (owner, lastFromSender) => {
  let className = 'flex flex-col gap-1 py-2 px-3 rounded-t-xl'
  if (owner) {
    className += ' rounded-bl-xl bg-light_coral text-white'
  } else {
    className += ' rounded-br-xl bg-white text-black'
  }
  if (!lastFromSender) {
    className += ' rounded-b-xl'
  }

  return className
}

const formatText = text => {
  let formattedText = text
    ?.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>') // Bold
    .replace(/\*(.*?)\*/g, '<em>$1</em>') // Italic
    .replace(/\n/g, '&nbsp;<br>') // Newline handling

  return (
    <p
      className='text-sm whitespace-pre-wrap'
      dangerouslySetInnerHTML={{ __html: formattedText }}
    />
  )
}
