import useFetch from '../../hooks/useFetch'
import { useEffect, useState } from 'react'
import { MenuItem, Select, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material'
import LobbiesList from './LobbiesList'
import LobbyInfo from '../../common/LobbyInfo'
import { urlApi } from '../../config'
import { DateRangePicker } from 'mui-daterange-picker'
import Modal from 'react-modal'

const Lobbies = ({ selectedLobby, setSelectedLobby, hidden }) => {
  const { loading, data } = useFetch(`${urlApi}/admin/lobbies`, {}, true)
  const [filters, setFilters] = useState(['active'])
  const [lobbyFilter, setLobbyFilter] = useState('')
  const [originSearch, setOriginSearch] = useState('')
  const [destinationSearch, setDestinationSearch] = useState('')
  const [nameSearch, setNameSearch] = useState('')
  const [dateRange, setDateRange] = useState({})
  const [openDatePicker, setOpenDatePicker] = useState(false)
  const [fields, setFields] = useState({
    filters,
    originSearch,
    destinationSearch,
    nameSearch,
    dateRange
  }) //Contendra todos los filtros dentro
  const [length, setLength] = useState('?')

  useEffect(() => {
    setFields({
      filters,
      lobbyFilter,
      nameSearch,
      originSearch,
      destinationSearch,
      dateRange
    })
  }, [filters, originSearch, destinationSearch, nameSearch, dateRange, lobbyFilter])

  const handleFilters = (event, newFilters) => {
    setFilters(newFilters)
  }

  return (
    <div className='w-full' hidden={hidden}>
      <h2 className='text-center text-2xl'>Lobbies</h2>
      <div className='flex justify-center'>
        <ToggleButtonGroup size='small' value={filters} onChange={handleFilters}>
          <ToggleButton value='company'>Con acompañantes</ToggleButton>
          <ToggleButton value='nodocumented'>Sin documentar</ToggleButton>
          <ToggleButton value='active'>Viajes nuevos</ToggleButton>
          <ToggleButton value='orderByTime'>Ordenar por tiemp</ToggleButton>
        </ToggleButtonGroup>
      </div>
      <div className='flex justify-center gap-2 mt-2'>
        <TextField
          size='small'
          label='Nombre/Id'
          onChange={evt => setNameSearch(evt.target.value)}
        />
        <TextField
          size='small'
          label='Origen'
          onChange={evt => setOriginSearch(evt.target.value)}
        />
        <TextField
          size='small'
          label='Destino'
          onChange={evt => setDestinationSearch(evt.target.value)}
        />
      </div>
      <div className='flex justify-center my-2 gap-10 pl-10'>
        <Select defaultValue='none' size='small' onChange={evt => setLobbyFilter(evt.target.value)}>
          <MenuItem value={'pending'} sx={{ backgroundColor: '#faedcc' }}>
            Pendiente
          </MenuItem>
          <MenuItem value={'completed'} sx={{ backgroundColor: '#cdeec8' }}>
            Completado
          </MenuItem>
          <MenuItem value={'cancelled'} sx={{ backgroundColor: '#fae3de' }}>
            Cancelado
          </MenuItem>
          <MenuItem value={'not-cancelled'}>No cancelados</MenuItem>
          <MenuItem value={'none'}>Sin filtro</MenuItem>
        </Select>
        <div className='flex justify-end'>
          <button
            className='bg-gray-300 ml-36 rounded-md py-1 px-3 shadow-2xl'
            onClick={() => setOpenDatePicker(!openDatePicker)}
          >
            Rango de fecha
          </button>
        </div>
        {dateRange?.startDate && (
          <div className='text-gray-500 flex flex-col justify-center'>
            {`${dateRange.startDate.toLocaleDateString()} - ${dateRange.endDate.toLocaleDateString()}`}
          </div>
        )}
        <button
          disabled={Object.keys(dateRange).length == 0}
          className='bg-red-500 disabled:bg-red-200 rounded-md py-1 px-4 shadow-2xl'
          style={{ width: '5%' }}
          onClick={() => setDateRange({})}
        >
          x
        </button>

        <div style={{ width: '20%' }}>
          <p className='text-xl flex justify-end mt-1 mx-5'>Resultados: {length}</p>
        </div>
      </div>
      <DateRangePicker
        open={openDatePicker}
        toggle={() => setOpenDatePicker(!openDatePicker)}
        onChange={range => {
          setDateRange(range)
          setOpenDatePicker(!openDatePicker)
        }}
      />
      {loading ? (
        <p>Cargando...</p>
      ) : (
        <LobbiesList
          data={data}
          setSelectedLobby={setSelectedLobby}
          fields={fields}
          setLength={setLength}
        />
      )}
      {Object.keys(selectedLobby).length != 0 ? (
        <PreLobbyInfo lobbyId={selectedLobby._id} setSelectedLobby={setSelectedLobby} />
      ) : (
        <></>
      )}
    </div>
  )
}

const PreLobbyInfo = ({ lobbyId, setSelectedLobby }) => {
  const { loading, data } = useFetch(`${urlApi}/lobbies/id/${lobbyId}`)
  const [visible, setVisible] = useState(true)

  useEffect(() => {
    if (!loading) setVisible(false)
  }, [loading])

  return (
    <>
      <Modal
        isOpen={visible}
        onRequestClose={() => setVisible(false)}
        style={{
          overlay: {
            backgroundColor: 'rgba(0,0,0,0.5)'
          },
          content: {
            width: '50%',
            height: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: 'auto',
            borderRadius: '10px',
            border: 'none',
            backgroundColor: '#f8f8f8',
            fontSize: '1.5rem'
          }
        }}
      >
        Cargando...
      </Modal>
      {!loading && <LobbyInfo lobby={data} setSelected={setSelectedLobby} />}
    </>
  )
}

export default Lobbies
