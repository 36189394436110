import useFetch from '../../hooks/useFetch'
import { useState } from 'react'
import { urlApi } from '../../config'
import Alert from '../Alert'

export default ({ lobby, companionId }) => {
  const [alert, setAlert] = useState(true)
  const { loading, responseOk } = useFetch(`${urlApi}/admin/lobbies/companions/${lobby._id}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      user_id: companionId
    })
  })

  return (
    <Alert
      visible={alert}
      onClose={() => {
        if (!responseOk) {
          setAlert(false)
        } else {
          setAlert(false)
        }
      }}
      hideButton={loading}
    >
      {
        loading ?
          <>Cargando...</>
          :
          responseOk ?
            <div className='text-green-700 font-extrabold text-2xl'>Companion borrado con exito (al actualizar la página se verá el cambio)</div>
            :
            <div className='text-red-600 font-extrabold text-2xl'>Error al borrar el companion</div>
      }
    </Alert>
  )
}
