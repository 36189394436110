import { Room, Flag } from '@mui/icons-material'

const Locations = ({ originName, destinationName }) => {
    return (
        <div className='flex flex-col justify-around text-left' >
            <div>
                <div className='flex gap-2 mr-1' >
                    <Room style={{ color: '#c65944' }} fontSize='large'/>
                    <div className='text-xl'>{originName}</div>
                </div>
                <div className='flex gap-2 mr-1 mt-5' >
                    <Flag style={{ color: '#c65944' }} fontSize='large'/>
                    <div className='text-xl'>{destinationName}</div>
                </div>
            </div>
        </div>
    )
}

export default Locations