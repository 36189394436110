
const Icon = ({ img, width = 20, height = 20, alt }) => {
  return (
    <img
      src={require('../../assets/icons/' + img)}
      width={width}
      height={height}
      alt={alt}
    />
  )
}

export default Icon