import { useEffect, useRef, useState } from 'react'
import useFetch from '../../hooks/useFetch'
import { urlApi } from '../../config'
import LoadingIndicator from '../../common/LoadingIndicator'
import ChatBubble from './ChatBubble'
import Input from './Input'
import { formatChatDate, sortChannels } from '../../utils'
import { ADMIN_PROFILE } from '../../config/Chat'
import { useAuth0 } from '@auth0/auth0-react'

export default ({
  selectedChannelId,
  selectedChannel,
  socket,
  channels,
  setChannels,
  socketDisconnected
}) => {
  const { getAccessTokenSilently } = useAuth0()
  const user = ADMIN_PROFILE.user_id
  const { loading, data, responseOk } = useFetch(`${urlApi}/admin/channels/${selectedChannelId}`)
  const [channel, setChannel] = useState()
  const [messages, setMessages] = useState()
  const [users, setUsers] = useState()
  const [readChannel, setReadChannel] = useState(false)
  const bottomRef = useRef()

  const readMessages = async () => {
    const token = await getAccessTokenSilently()
    const request = await fetch(`${urlApi}/admin/channels/${selectedChannelId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'authorization': `Bearer ${token}`
      }
    })

    if (request.ok === false) return

    const read = await request.json().then(data => data.read)

    setReadChannel(true)
  }

  useEffect(() => {
    if (readChannel && messages && channel) {
      setMessages(
        messages.map(message => {
          return {
            ...message,
            read_by: [...message.read_by, user]
          }
        })
      )

      setChannels(
        sortChannels([
          ...channels.filter(channel => channel._id !== selectedChannelId),
          {
            ...channel,
            messages: messages.map(message => {
              return {
                ...message,
                read_by: [...message.read_by, user]
              }
            })
          }
        ])
      )
      setReadChannel(false)
    }
  }, [readChannel, messages, channel])

  useEffect(() => {
    const updateMessages = async () => {
      const unreadMessages = data.messages
        .filter(message => message.user_id != user)
        .some(message => !message.read_by?.includes(user))
      if (unreadMessages) {
        await readMessages(data)
      }
    }

    if (responseOk) {
      setChannel(data)
      setMessages(data.messages)
      setUsers(data.users)
      updateMessages()
    } else if (!loading) {
      setChannel(selectedChannel)
      setMessages(selectedChannel.messages)
      setUsers(selectedChannel.users)
    }
  }, [loading])

  useEffect(() => {
    const updateMessages = async () => {
      const channelMessages = channels.find(channel => channel._id === selectedChannelId)?.messages
      if (channelMessages?.length < messages?.length) {
        setMessages([
          messages.map(message => {
            return {
              ...message,
              not_sent: false
            }
          })
        ])
      } else {
        setMessages(channelMessages)
      }
      if (channelMessages?.length > messages?.length) {
        await readMessages(channels.find(channel => channel._id === selectedChannelId))
      }
    }

    updateMessages()
  }, [channels])

  const handleSendMessage = message => {
    socket.current.emit('send', {
      channel_id: channel._id,
      text: message
    })
    let newMessage = {
      id: messages.length + 1,
      text: message,
      user_id: user,
      date: new Date().toISOString(),
      read_by: [],
      user: {}
    }
    if (socketDisconnected) {
      newMessage = {
        ...newMessage,
        not_sent: true
      }
    }
    setMessages([...messages, newMessage])
    setChannels(
      sortChannels([
        ...channels.filter(channel => channel._id !== selectedChannelId),
        {
          ...channel,
          messages: [...messages, newMessage]
        }
      ])
    )
  }

  const handleDeleteMessage = message => {
    socket.current.emit('delete', {
      channel_id: channel._id,
      message_id: message.id
    })
    const updatedMessages = messages.map(m => {
      if (m.id === message.id) {
        return {
          ...m,
          text: 'Mensaje eliminado por el administrador'
        }
      }
      return m
    })
    setMessages(updatedMessages)
    setChannels(
      sortChannels([
        ...channels.filter(channel => channel._id !== selectedChannelId),
        {
          ...channel,
          messages: updatedMessages
        }
      ])
    )
  }

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' })
  }, [messages])

  return (
    <div className='flex flex-col justify-end w-full h-full rounded-tl-xl pb-4'>
      <div className='flex flex-col overflow-auto gap-2 bg-light_gray p-3 h-screen'>
        {messages && users ? (
          <>
            <MessageList
              messages={messages}
              user={user}
              users={users}
              handleDeleteMessage={handleDeleteMessage}
            />
            <div ref={bottomRef} />
          </>
        ) : (
          <div className='h-full w-full flex items-center justify-center'>
            <LoadingIndicator />
          </div>
        )}
      </div>
      <Input channel={channel} sendMessage={handleSendMessage} disabled={!channel} />
    </div>
  )
}

const MessageList = ({ messages, user, users, handleDeleteMessage }) => {
  return messages?.map((message, index) => {
    const owner = message.user_id == user
    const sender =
      message.user && message.user.name
        ? message.user
        : users.find(user => user.user_id == message.user_id) ?? ADMIN_PROFILE
    const previousMessage = messages[index - 1]
    const nextMessage = messages.length - 1 == index ? null : messages[index + 1]
    const firstMessageFromSender = !previousMessage || previousMessage.user_id != message.user_id
    const lastMessageFromSender = !nextMessage || nextMessage.user_id != message.user_id
    const sameDay =
      previousMessage &&
      new Date(previousMessage.date).toLocaleDateString() ==
        new Date(message.date).toLocaleDateString()
    return (
      <>
        {!sameDay && <DayDivider day={formatChatDate(message.date)} />}
        <ChatBubble
          handleDeleteMessage={handleDeleteMessage}
          key={index}
          owner={owner}
          sender={sender}
          message={message}
          firstFromSender={firstMessageFromSender}
          lastFromSender={lastMessageFromSender}
        />
      </>
    )
  })
}

const DayDivider = ({ day }) => {
  return (
    <div className='w-full flex justify-center sticky top-0'>
      <div className='flex items-center justify-center bg-gray rounded-full w-20 sticky my-4'>
        <p className='text-sm text-white py-1 px-3'>{day}</p>
      </div>
    </div>
  )
}
