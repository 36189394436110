import { TextField } from '@mui/material'
import { useState } from 'react'
import useFetch from '../../hooks/useFetch'
import { urlApi } from '../../config'
import { useEffect } from 'react'
import { ArrowBackIosNew, ContentCopyRounded } from '@mui/icons-material'
import LobbyInfo from '../../common/LobbyInfo'
import { isoDateToWeekday } from '../../utils'

export default () => {
  const [search, setSearch] = useState(false)
  const [text, setText] = useState('')
  const [paymentInfo, setPaymentInfo] = useState()
  const [lobby, setLobby] = useState({})
  const [getLobby, setGetLobby] = useState(false)
  const [payments, setPayments] = useState([])
  const { loading, data } = useFetch(`${urlApi}/admin/driver-payments`)

  useEffect(() => {
    const handleEnter = e => {
      if (e.key === 'Enter') {
        setPaymentInfo()
        setSearch(false)
        setTimeout(() => {
          setSearch(true)
        }, 100)
      }
    }
    document.addEventListener('keydown', handleEnter)
    return () => {
      document.removeEventListener('keydown', handleEnter)
    }
  }, [text])

  useEffect(() => {
    if (data) {
      setPayments(data)
    }
  }, [data])

  return (
    <>
      <div className='flex flex-col bg-gray-200 rounded-xl px-7 py-5 overflow-auto mx-6 h-screen pb-20'>
        <div className='flex flex-row text-center justify-between items-center'>
          <TextField
            label='Lobby Id (funciona también para pagos completados)'
            variant='outlined'
            value={text}
            onChange={e => {
              let val = e.target.value
              setText(val)
            }}
            className='w-1/2'
          />
          <button
            className='bg-gray-300 rounded-md py-1 px-3 shadow-2xl mb-2 bg-[#FF0000] text-[#fff] w-1/6 h-10'
            onClick={() => setSearch(false)}
          >
            Cancelar
          </button>
          <button
            className='bg-gray-300 rounded-md py-1 px-3 shadow-2xl mb-2 bg-[#d65944] text-[#fff] w-1/6 h-10'
            onClick={() => {
              setPaymentInfo()
              setSearch(false)
              setTimeout(() => {
                setSearch(true)
              }, 100)
            }}
          >
            Buscar
          </button>
        </div>
        {paymentInfo ? (
          <PaymentInfo
            paymentInfo={paymentInfo}
            setPaymentInfo={setPaymentInfo}
            setLobby={setLobby}
            getLobby={getLobby}
            setGetLobby={setGetLobby}
            setPayments={setPayments}
            payments={payments}
          />
        ) : search ? (
          <PaymentRequest text={text} setPaymentInfo={setPaymentInfo} />
        ) : loading ? (
          <>Cargando...</>
        ) : data.status == 404 || data.status == 500 ? (
          <div className='flex flex-col justify-center items-center mt-10'>
            <p className='text-2xl text-center'>Error en la busqueda</p>
          </div>
        ) : data.length == 0 ? (
          <div className='flex flex-col justify-center items-center mt-10'>
            <p className='text-2xl text-center'>No hay pagos pendientes</p>
          </div>
        ) : (
          <div>
            {payments.map((payment, index) => (
              <PaymentCard key={index} payment={payment} setPaymentInfo={setPaymentInfo} />
            ))}
          </div>
        )}
      </div>
      {Object.keys(lobby).length > 0 && <LobbyInfo lobby={lobby} setSelected={setLobby} />}
    </>
  )
}

const PaymentRequest = ({ text, setPaymentInfo }) => {
  const { loading, data } = useFetch(`${urlApi}/admin/driver-payments/${text}`)

  return (
    <div>
      {loading ? (
        <>Cargando...</>
      ) : data.status == 404 || data.status == 500 ? (
        <div className='flex flex-col justify-center items-center mt-10'>
          <p className='text-2xl text-center'>Error en la busqueda</p>
        </div>
      ) : data.length == 0 ? (
        <div className='flex flex-col justify-center items-center mt-10'>
          <p className='text-2xl text-center'>No se encontró ningún pago para esta lobby</p>
        </div>
      ) : (
        <PaymentCard payment={data} setPaymentInfo={setPaymentInfo} />
      )}
    </div>
  )
}

const PaymentCard = ({ payment, setPaymentInfo }) => {
  const createdDate = new Date(payment.created)
  const hasItBeen24Hours = new Date() - createdDate > 24 * 60 * 60 * 1000

  return (
    <div
      className='flex flex-col my-2 px-3 py-3 justify-between rounded-md cursor-pointer shadow-md gap-2'
      onClick={() => setPaymentInfo(payment)}
    >
      <p>
        {createdDate.toLocaleString()} {hasItBeen24Hours && '- Pasaron 24hs 👍'}
      </p>
      <p>Lobby: {payment.lobby_id}</p>
      <p>Usuario: {payment.user_id}</p>
      <p>Monto: {payment.amount}</p>
      <p className={payment.status === 'completed' ? 'text-green-400' : 'text-yellow-400'}>
        {payment.status === 'completed' ? 'Completado' : 'Pendiente'}
      </p>
    </div>
  )
}

const PaymentInfo = ({
  paymentInfo,
  setPaymentInfo,
  setLobby,
  setGetLobby,
  getLobby,
  setPayments,
  payments
}) => {
  const [complete, setComplete] = useState(false)

  const handleCopy = text => {
    navigator.clipboard.writeText(text)
  }

  return (
    <div>
      <ArrowBackIosNew
        className='cursor-pointer mt-3 hover:opacity-50 text-[#d65944]'
        onClick={() => setPaymentInfo()}
      />
      <div className='flex flex-col justify-center mb-3 text-lg items-center gap-4'>
        {getLobby ? (
          <GetLobby setLobby={setLobby} setGetLobby={setGetLobby} lobbyId={paymentInfo.lobby_id} />
        ) : (
          <p onClick={() => setGetLobby(true)} className='text-[#d65944] cursor-pointer'>
            Lobby: {paymentInfo.lobby_id}
          </p>
        )}
        <p>Usuario: {paymentInfo.user_id}</p>
        <p className='font-bold text-xl'>Cuenta bancaria</p>
        <p>Banco: {paymentInfo.bank_account.bank_name}</p>
        <div className='flex flex-row gap-2'>
          <p>Numero de cuenta: {paymentInfo.bank_account.account_number}</p>
          <ContentCopyRounded
            className='cursor-pointer hover:opacity-50 text-[#d65944]'
            onClick={() => handleCopy(paymentInfo.bank_account.account_number)}
          />
        </div>
        <p>Tipo de cuenta: {getAccountType(paymentInfo.bank_account.account_type)}</p>
        <div className='flex flex-row gap-2'>
          <p>Nombre titular: {paymentInfo.bank_account.owner_full_name}</p>
          <ContentCopyRounded
            className='cursor-pointer hover:opacity-50 text-[#d65944]'
            onClick={() => handleCopy(paymentInfo.bank_account.owner_full_name)}
          />
        </div>
        <div className='flex flex-row gap-2'>
          <p>CI titular: {paymentInfo.bank_account.owner_id_number}</p>
          <ContentCopyRounded
            className='cursor-pointer hover:opacity-50 text-[#d65944]'
            onClick={() => handleCopy(paymentInfo.bank_account.owner_id_number)}
          />
        </div>
        <p className='font-bold text-xl'>Monto: {paymentInfo.amount}</p>
        {paymentInfo.status === 'completed' ? (
          <p className='text-green-500'>
            Pago Completado: {isoDateToWeekday(paymentInfo.payment_completed)}
          </p>
        ) : complete === 'complete' ? (
          <CompletePayment
            setComplete={setComplete}
            paymentId={paymentInfo._id}
            setPayments={setPayments}
            payments={payments}
          />
        ) : complete === 'completed' ? (
          <></>
        ) : (
          <button
            className='bg-[#d65944] text-white font-bold py-2 px-4 rounded-xl mx-auto mt-3'
            onClick={() => setComplete('complete')}
          >
            Marcar como completado
          </button>
        )}
      </div>
    </div>
  )
}

const getAccountType = type => {
  if (type === 'checking') return 'Cuenta corriente'
  else if (type === 'savings') return 'Caja de ahorro'
  else if (type === 'cashier') return 'Cuenta cajero'
}

const GetLobby = ({ setLobby, setGetLobby, lobbyId }) => {
  const { loading, data, responseOk } = useFetch(`${urlApi}/lobbies/id/${lobbyId}`)
  useEffect(() => {
    if (!loading && responseOk) {
      setLobby(data)
      setGetLobby(false)
    }
  }, [data])
  return <p>Cargando...</p>
}

const CompletePayment = ({ setComplete, paymentId, setPayments, payments }) => {
  const { loading, responseOk } = useFetch(`${urlApi}/admin/driver-payments/${paymentId}`, {
    method: 'POST'
  })

  useEffect(() => {
    if (responseOk) {
      alert('Pago completado')
      setPayments([
        ...payments.filter(payment => payment._id !== paymentId),
        {
          ...payments.find(payment => payment._id === paymentId),
          status: 'completed',
          payment_completed: new Date().toISOString()
        }
      ])
      setComplete('completed')
    } else if (!loading) {
      alert('Error al completar')
      setComplete(false)
    }
  }, [loading])

  return (
    <div className='flex flex-col justify-center items-center gap-4'>
      <p>Cargando...</p>
    </div>
  )
}
