import { useState } from 'react'
import { DateRangePicker } from 'mui-daterange-picker'
import FetchStatistics from './FetchStatistics'

export default ({ navigate, navigator, setOpenOld }) => {
  const [openDatePicker, setOpenDatePicker] = useState(false)
  const [dateRange, setDateRange] = useState({})

  return (
    <div
      className='w-full absolute text-xl m-12 mb-6 bg-gray-200 rounded-xl p-5 px-7 w-[90%]'
      hidden={navigator == 'Departments'}
    >
      <div className='w-full'>
        <div className='text-center'>
          <button
            className='bg-gray-300 rounded-md py-1 px-3 shadow-2xl mb-2'
            onClick={() => setOpenOld(false)}
          >
            Close
          </button>
        </div>
        <div className='text-center'>
          <button
            className='bg-gray-300 rounded-md py-1 px-3 shadow-2xl mb-2'
            onClick={() => navigate('Departments')}
          >
            Departamentos
          </button>
        </div>
        <div className='text-center font-extrabold pb-5 text-2xl'>Estadísticas (Completados)</div>
        <div className='text-center'>La fecha filtra por departure_time, no por fecha que se compró</div>
        <div className='text-center'>
          <button
            className='bg-gray-300 rounded-md py-1 px-3 shadow-2xl'
            onClick={() => setOpenDatePicker(!openDatePicker)}
          >
            Rango de fecha
          </button>
          <button
            disabled={Object.keys(dateRange).length == 0}
            className='bg-red-500 disabled:bg-red-200 rounded-md py-1 px-4 shadow-2xl'
            style={{ width: '8%' }}
            onClick={() => setDateRange({})}
          >
            x
          </button>
          <DateRangePicker
            open={openDatePicker}
            toggle={() => setOpenDatePicker(!openDatePicker)}
            onChange={(range) => { setDateRange(range); setOpenDatePicker(!openDatePicker) }}
          />
        </div>
        <FetchStatistics dateRange={dateRange} />
      </div>
    </div >
  )
}