import Modal from 'react-modal'
import Button from '../Button'

const Alert = ({ children, visible, onClose, onSubmit, hideButton, style }) => {
  const customStyles = {
    content: {
      borderRadius: '1.5rem',
      height: 300,
      margin: 'auto',
      paddingTop: '2.5rem',
      paddingLeft: '2rem',
      ...style
    },
    overlay: {
      zIndex: 100,
      backgroundColor: 'rgba(0,0,0,0)',
    }
  }

  return (
    <Modal
      isOpen={visible}
      style={customStyles}
      contentLabel='Alert'
      onRequestClose={onClose}
    >
      <div className='h-full flex flex-col justify-around' >
        <div className='text-center text-lg' >
          {children}
        </div>
        {
          onSubmit ?
            <div className='flex gap-3' >
              <Button
                visible={visible}
                name={'Aceptar'}
                style={{ marginBottom: 15, zIndex: 300, fontSize: '21px' }}
                onClick={() => {
                  onSubmit()
                  onClose()
                }}
              />
              <Button
                visible={visible}
                name={'Cancelar'}
                style={{
                  marginBottom: 15,
                  zIndex: 300,
                  backgroundColor: 'gray',
                  fontSize: '18px'
                }}
                onClick={onClose}
              />
            </div>
            :
            <div
              className='w-full text-center'
              hidden={hideButton}
            >
              <Button
                visible={visible}
                name={'Aceptar'}
                style={{ marginBottom: 15, zIndex: 300 }}
                onClick={onClose}
              />
            </div>
        }

      </div>
    </Modal>
  )
}

export default Alert