import { useState, useEffect } from 'react'
import { urlApi } from '../../config'
import useFetch from '../../hooks/useFetch'
import OldStatistics from './OldStatistics'
import ChartsEmbedSDK from '@mongodb-js/charts-embed-dom'
import RangeDates from './RangeDates'

const StatisticsSupply = ({ navigate, navigator }) => {
  const [dashboard, setDashboard] = useState(null)
  const [openOld, setOpenOld] = useState(false)
  const [dateRange, setDateRange] = useState(null)
  const { loading, data } = useFetch(`${urlApi}/admin/chart-token`)

  useEffect(() => {
    if (dateRange === false) {
      dashboard.setFilter({})
    } else if (dateRange) {
      dashboard.setFilter({
        $or: [
          {
            ignoreFilter: true
          },
          {
            range: { $gte: dateRange.start, $lte: dateRange.end }
          }
        ]
      })
    }
  }, [dateRange])

  useEffect(() => {
    if (!loading && data && !openOld && dashboard) {
      console.log('rerender')
      dashboard
        .render(document.getElementById('dashboard'))
        .catch(() => window.alert('Dashboard failed to initialise'))
    }
  }, [loading, dashboard])

  useEffect(() => {
    if (!loading && data && !openOld) {
      console.log('recargo')
      const sdk = new ChartsEmbedSDK({
        baseUrl: 'https://charts.mongodb.com/charts-sin-nombre-wrerk',
        getUserToken: () => data.token
      })

      setDashboard(sdk.createDashboard({
        dashboardId: '3b6c3b75-d6ed-417d-8605-f851102865eb',
      }))
    }

  }, [loading])

  if (loading) return <div className='w-full text-center'><p>...</p></div>

  if (openOld) return (
    <OldStatistics
      navigate={navigate}
      navigator={navigator}
      setOpenOld={setOpenOld}
    />
  )

  return (
    <div className='flex flex-col h-screen'>
      <h1 className='text-yellow-600 text-2xl font-bold mb-7 text-center'>Supply</h1>
      <div className='flex justify-center mt-[-30px] pb-2'>
        <div>
          <div className='w-full flex justify-center'>
            <div
              className='bg-gray-500 px-2 rounded-lg cursor-pointer'
              onClick={() => setOpenOld(true)}
            >
              Old Panel
            </div>
          </div>
          <RangeDates
            setDateRange={setDateRange}
          />
        </div>
      </div>
      <div className='flex-grow' id='dashboard'></div>
    </div>
  )

}


export default StatisticsSupply