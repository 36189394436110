export const modalStyles = {
  content: {
    borderRadius: '1.5rem',
    padding: '2rem',
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: '50%',
    height: '50%'
  },
  overlay: {
    zIndex: 100,
    backgroundColor: 'rgba(0,0,0,0)'
  }
}
