import { urlApi } from '../../config'
import { useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import auth0Config from '../../config/Auth0'

const FetchStatistics = ({ dateRange }) => {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState(true)
  const { getAccessTokenSilently } = useAuth0()

  useEffect(() => {
    setLoading(true)
    const startDate = dateRange?.startDate?.toISOString()
    let endDate = dateRange?.endDate?.toISOString()
    //if start and end are equal, add one day to endDate
    if (endDate && startDate == endDate) {
      endDate = new Date(endDate)
      endDate.setDate(endDate.getDate() + 1)
      endDate = endDate.toISOString()
    }
    let params = ''

    if (endDate && startDate) {
      params = `?min_time=${startDate}&max_time=${endDate}`
    }
    getAccessTokenSilently({
      audience: auth0Config.audience,
    }).then(token => {
      fetch(`${urlApi}/admin/statistics${params}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      }).then(res => res.json())
        .then(data => {
          setData(data)
          setLoading(false)
        })
    })
  }, [dateRange])

  return (
    <>
      {
        loading ?
          <div className='text-center'>Cargando...</div>
          :
          <div className='flex justify-center'>
            <div className='w-full items-center'>
              <div className='w-full flex gap-40 pt-4 pl-5'>
                <div>
                  {
                    data.statistics.slice(0, 9).map((statistic, index) => <Field key={index} name={statistic.name} value={statistic.value} />)
                  }
                </div>
                <div>
                  {
                    data.statistics.slice(9).map((statistic, index) => <Field key={index} name={statistic.name} value={statistic.value} />)
                  }
                </div>
              </div>
              <div className='w-full text-xl m-12 mb-6 bg-gray-200 rounded-xl p-5 pt-0 px-7 w-[90%]'>
                <div className='w-full flex flex-col'>
                  <div className='flex gap-2'>
                    <div>
                      <div className='text-center w-full font-extrabold pb-5 text-2xl'>Companions</div>
                      <>
                        {
                          data.rates.companions.map((rate, index) => <Field key={index} name={rate.name} value={rate.value} />)
                        }
                      </>
                    </div>
                    <div>
                      <div className='text-center w-full font-extrabold pb-5 text-2xl'>Drivers</div>
                      <>
                        {
                          data.rates.drivers.map((rate, index) => <Field key={index} name={rate.name} value={rate.value} />)
                        }
                      </>
                    </div>
                    <div>
                      <div className='text-center w-full font-extrabold pb-5 text-2xl'>Cancel Reasons con acompañantes</div>
                      <>
                        {
                          data.rates?.cancelReasonsWithJoines?.map((rate, index) => <Field key={index} name={rate.name} value={rate.value} />)
                        }
                      </>
                    </div>
                    <div>
                      <div className='text-center w-full font-extrabold pb-5 text-2xl'>Cancel Reasons sin acompañantes</div>
                      <>
                        {
                          data.rates?.cancelReasonsWithoutJoines?.map((rate, index) => <Field key={index} name={rate.name} value={rate.value} />)
                        }
                      </>
                    </div>
                  </div>
                </div>
              </div >
            </div >
          </div>
      }
    </>
  )
}

const Field = ({ name, value }) =>
  <div className='flex gap-2 pb-2'>
    <div className='font-semibold'>
      {name}:
    </div>
    <div>
      {value}
    </div>
  </div>

export default FetchStatistics