import useFetch from '../../hooks/useFetch'
import { useState } from 'react'
import Modal from 'react-modal'
import { urlApi } from '../../config'
import CompanionCard from '../CompanionCard'
import CompanionInfo from '../CompanionInfo'
import { IconButton } from '@mui/material'
import { Close } from '@mui/icons-material'

export default ({ lobby, setMatchesCompanions }) => {
  const [visible, setVisible] = useState(true)
  const [selectedCompanion, setSelectedCompanion] = useState(false)
  const { loading, data, responseOk } = useFetch(`${urlApi}/admin/lobbies/matches/${lobby._id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })

  const customStyles = {
    content: {
      borderRadius: '1.5rem',
      marginTop: 'auto',
      marginBottom: 'auto',
      paddingLeft: '1.6rem',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      overflow: 'hidden',
    },
    overlay: {
      zIndex: 100,
      backgroundColor: 'rgba(0,0,0,0)',
    }
  }

  return (
    <Modal
      isOpen={visible}
      onRequestClose={() => {
        setMatchesCompanions(false)
        setVisible(false)
      }}
      style={customStyles}
      contentLabel="Example Modal"
    >
      {
        loading ?
          <div className='text-2xl text-center'>
            Cargando...
          </div>
          :
          !responseOk ?
            <div className='text-red-600 font-extrabold text-2xl'>Error al buscar coincidencias</div>
            :
            <div className='pt-52'>
              <div className='flex justify-between'>
                <IconButton
                  onClick={() => {
                    setMatchesCompanions(false)
                    setVisible(false)
                  }}
                  style={{ paddingTop: 20 }}
                >
                  <Close />
                </IconButton>
                <h2 className='mt-10 mr-6 text-2xl text-orange-700 pb-5 text-center'>Cantidad: {data.length}</h2>
              </div>
              <h2 className='text-2xl text-orange-700 pb-5 text-center'>Coincidencias de acompañantes</h2>
              <div >
                <ol className='overflow-auto h-screen pb-10'>
                  {
                    data.map(companion =>
                      <li className='flex'>
                        <CompanionCard
                          companion={companion}
                          setSelected={setSelectedCompanion}
                          recommendatedLobby={lobby}
                        />
                      </li>
                    )
                  }
                  <li className='text-xl text-center mb-64'>
                    FIN
                  </li>
                </ol>
              </div>
            </div>
      }
      {
        Object.keys(selectedCompanion).length != 0 &&
        <CompanionInfo
          companion={selectedCompanion}
          setSelected={setSelectedCompanion}
        />
      }
    </Modal>
  )
}
