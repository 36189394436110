import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane, faPlus } from '@fortawesome/free-solid-svg-icons'
import { TextField, ThemeProvider } from '@mui/material'
import { containsContactInfo, muiInputTheme } from '../../utils'
import { useState } from 'react'

export default ({ sendMessage, disabled }) => {
  const [message, setMessage] = useState('')

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && e.shiftKey === false && message.trim().length > 0 && !isMobile()) {
      sendMessage(message)
      if (!containsContactInfo(message)) setMessage('')
      e.preventDefault()
    }
  }

  const isMobile = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)
  }

  return (
    <div className={`flex flex-row items-center gap-3 mt-3 px-4 pb-[9rem]`}>
      <FontAwesomeIcon icon={faPlus} size='lg' color='white' />
      <ThemeProvider theme={muiInputTheme}>
        <TextField
          fullWidth
          multiline
          maxRows={4}
          InputProps={{ style: { padding: 10 } }}
          value={message}
          onChange={e => setMessage(e.target.value)}
          disabled={disabled}
          onKeyDown={handleKeyDown}
        />
      </ThemeProvider>
      <div
        onClick={() => {
          if (message.trim().length == 0) return
          sendMessage(message)
          if (!containsContactInfo(message)) setMessage('')
        }}
        className={`flex items-center justify-center p-2 w-10 h-10 rounded-[50%] ${message.trim().length > 0 ? 'bg-light_coral focus:bg-primary' : 'bg-gray'} cursor-pointer`}>
        <FontAwesomeIcon icon={faPaperPlane} color='white' />
      </div>
    </div>
  )
}