import { useState } from 'react'
import Modal from 'react-modal'
import Locations from './Locations'
import Button from '../Button'
import { Divider, IconButton, TextField } from '@mui/material'
import { Close, AccessTime, Comment, DeleteForever } from '@mui/icons-material'
import Alert from '../Alert'
import UserInfo from '../UserInfo'
import SendComment from './SendComment'
import { People } from '@mui/icons-material'
import DeleteCompanion from './DeleteCompanion'

const dias = ['Domingo ', 'Lunes ', 'Martes ', 'Miercoles ', 'Jueves ', 'Viernes ', 'Sabado ']
const meses = ['Enero ', 'Febrero ', 'Marzo ', 'Abril ', 'Mayo ',
  'Junio ', 'Julio ', 'Agosto ', 'Septiembre', 'Octubre ',
  'Noviembre ', 'Diciembre ']

const CompanionInfo = ({ companion, setSelected }) => {
  const [adminComment, setAdminComment] = useState(companion?.Admin?.comment)
  const [userAlert, setUserAlert] = useState(false)
  const [visible, setVisible] = useState(Boolean(companion))
  const [sendComment, setSendComment] = useState(false)
  const [deleteCompanion, setDeleteCompanion] = useState(false)
  const [deleteAlert, setDeleteAlert] = useState(false)

  const departureDateTimeCreated = new Date((new Date(companion.created)).getTime())
  const departureTimeInUruguay = new Date((new Date(companion.created)).getTime() - 3 * 60 * 60 * 1000).toISOString()
  const departureTimeCreated = departureTimeInUruguay.substr(departureTimeInUruguay.indexOf('T') + 1, 5)
  const departureDateCreated = dias[departureDateTimeCreated.getDay()] + departureDateTimeCreated.getDate() + ' de ' + meses[departureDateTimeCreated.getMonth()]
  const time = companion.departure_time
  let timeToShow = time.substr(time.indexOf('-') + 4, 2) + '/' + time.substr(time.indexOf('-') + 1, 2)
  if (time.includes(',')) {
    const secondTime = time.substr(time.indexOf(',') + 1, time.length - time.indexOf(','))
    timeToShow = timeToShow + ' - ' + secondTime.substr(secondTime.indexOf('-') + 4, 2) + '/' + secondTime.substr(secondTime.indexOf('-') + 1, 2)
  }


  const customStyles = {
    content: {
      borderRadius: '1.5rem',
      marginTop: 'auto',
      marginBottom: 'auto',
      paddingLeft: '1.6rem',
      maxHeight: 700,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
    },
    overlay: {
      zIndex: 100,
      backgroundColor: 'rgba(0,0,0,0)',
    }
  }

  const handleClose = () => {
    setVisible(false)
    setSelected({})
  }

  const handleDelete = () => {
    setDeleteAlert(true)
  }

  return (
    <>
      <Modal
        isOpen={visible}
        onRequestClose={handleClose}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className='w-full justify-end flex'>
          <IconButton onClick={handleDelete} style={{ paddingTop: '0' }} >
            <DeleteForever />
          </IconButton>
          <IconButton onClick={handleClose} style={{ paddingTop: '0' }} >
            <Close />
          </IconButton>
        </div>
        <Divider />
        <div className='h-4/5 overflow-auto'>
          <div className='w-full flex flex-col items-center text-2xl' >
            <a
              onClick={() => setUserAlert(true)}
              className='mt-3 mb-3 text-blue-600 cursor-pointer'
            >
              {companion.User_info.name}
            </a>
            <TextField
              multiline
              label='Comentario de administrador'
              value={adminComment}
              onChange={evt => setAdminComment(evt.target.value)}
            />
            {
              companion?.comment ?
                <div className='flex gap-2 text-xl mt-3'>
                  <Comment style={{ color: '#c65944' }} fontSize='large' />
                  <div style={{ color: '#c65944' }} lassName='flex flex-row mr-3 mt-1'>
                    {companion.comment}
                  </div>
                </div>
                :
                <></>
            }
            <div className='flex gap-2 text-xl' >
              <People style={{ color: '#c65944' }} fontSize='large' />
              <div className='flex flex-col justify-center'>Asientos: {companion.seats}</div>
            </div>
          </div>
          <div className='w-full flex flex-col mt-2 text-2xl gap-4 sm:items-center'>
            <Locations
              originName={companion.origin_name}
              destinationName={companion.destination_name}
            />
            <div className='flex gap-3 text-xl' >
              <AccessTime style={{ color: '#c65944' }} fontSize='large' />
              <div classname='flex flex-row'>
                <div className='mt-1'>{timeToShow}</div>
              </div>
            </div>
            <div className='text-xl'>ID: {companion._id}</div>
            <div className='text-xl mb-2'>Agendado: {departureDateCreated} {departureTimeCreated}</div>
          </div>
        </div>
        <div className='flex flex-col'>
          <Divider />
          <Button
            name={'Enviar comentario'}
            style={{ marginTop: '10px', marginBottom: '6px', zIndex: 300 }}
            onClick={() => setSendComment(true)}
          />
          <Alert
            visible={userAlert}
            hideButton
            onClose={() => setUserAlert(false)}
            style={{ height: 510 }}
          >
            <UserInfo
              userId={companion.User_info.id}
              type='companion'
            />
          </Alert>
          <Alert
            hideButton
            visible={deleteAlert}
            onSubmit={() => {
              setDeleteCompanion(true)
              setDeleteAlert(false)
            }}
            onClose={() => setDeleteAlert(false)}
          >
            <div className='flex flex-col justify-center'>
              <div className='text-xl'>¿Estás seguro de que quieres eliminar este companion?</div>
              <div className='text-xl'>Esta acción no se puede deshacer.</div>
            </div>
          </Alert>
          {
            sendComment ?
              <SendComment
                companion={companion}
                comment={adminComment}
                setSendComment={setSendComment}
              />
              :
              <></>
          }
          {
            deleteCompanion ?
              <DeleteCompanion
                companion={companion}
              />
              :
              <></>
          }
        </div>
      </Modal>
    </>
  )
}

export default CompanionInfo
