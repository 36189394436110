import NotificationsIcon from '@mui/icons-material/Notifications'
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive'
import { useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'

const Menu = ({ notifications, children, setMessages, isMobile }) => {
  const { logout } = useAuth0()
  return (
    <div className='ml-4'>
      <div className='flex gap-4 m-1 items-center'>
        <div className='flex'>
          {Object.keys(notifications.filter(x => x.active)).length != 0 ? (
            <>
              <NotificationsActiveIcon fontSize='large' className='text-red-600' />
              <NotificationsIcon
                fontSize='large'
                className='absolute z-50 text-yellow-600 animate-ping'
              />
            </>
          ) : (
            <NotificationsIcon fontSize='large' />
          )}
        </div>
        <div
          className='bg-[#d65944] w-12 h-10 rounded-lg
              flex justify-center items-center cursor-pointer'
          onClick={() => setMessages(true)}
        >
          CHAT
        </div>
        {children}
        {!isMobile && (
          <div
            className='bg-gray-400 w-32 h-10 rounded-lg
              flex justify-center items-center cursor-pointer'
            onClick={() => logout({ returnTo: window.origin })}
          >
            Log out
          </div>
        )}
      </div>
    </div>
  )
}

export default Menu
