import { useEffect, useState } from 'react'
import { urlApi } from './config'
import Lobbies from './components/Lobbies/index'
import useFetch from './hooks/useFetch'
import Menu from './components/Menu'
import Messages from './components/Messages'
import Notifications from './components/Notifications'
import { useAuth0 } from '@auth0/auth0-react'
import StatisticsDemand from './components/Statistics/StatisticsDemand'
import StatisticsSupply from './components/Statistics/StatisticsSupply'
import DepartmentTable from './components/DepartmentTable'
import UserSearch from './components/UserSearch'
import { Drawer } from '@mui/material'
import DriverPayments from './components/DriverPayments'
import ChatBroadcast from './components/ChatBroadcast'
import NewsPopup from './components/NewsPopup'

const App = () => {
  const { isAuthenticated, isLoading, loginWithRedirect, logout } = useAuth0()
  const [selectedLobby, setSelectedLobby] = useState({})
  const [messages, setMessages] = useState(false)
  const defaultNavigation = localStorage.getItem('defaultNavigation')
  const [navigator, navigate] = useState(defaultNavigation ? defaultNavigation : 'Lobbies')
  const [openDrawer, setOpenDrawer] = useState(false)

  const { loading, data } = useFetch(`${urlApi}/admin/notifications`, {}, true)
  const [notifications, setNotifications] = useState([])

  useEffect(() => {
    if (data) {
      setNotifications(data.filter(notification => notification.type != 'companion').reverse())
    }
  }, [data])

  const isMobile = window.innerWidth < 768

  const urlParams = new URLSearchParams(window.location.search)
  if (urlParams.has('logout')) return logout()

  if (isLoading) {
    return <div className='w-full h-screen flex justify-center items-center'>Loading...</div>
  } else if (!isLoading && !isAuthenticated) {
    loginWithRedirect({
      appState: window.location.href
    })
    return (
      <div className='w-full h-screen flex justify-center items-center'>Inciando sesión...</div>
    )
  }
  return (
    <>
      {isMobile ? (
        <>
          <Drawer anchor='right' open={openDrawer} onClose={() => setOpenDrawer(false)}>
            <div className='flex flex-col gap-2 p-6 items-center'>
              <StatisticsButton
                navigator={navigator}
                navigate={navigate}
                component='StatisticsDemand'
                name='Demanda'
              />
              <StatisticsButton
                navigator={navigator}
                navigate={navigate}
                component='StatisticsSupply'
                name='Supply'
              />
              <UserSearchButton navigator={navigator} navigate={navigate} />
              <DriverPaymentsButton navigator={navigator} navigate={navigate} />
              <ChatBroadcastButton navigator={navigator} navigate={navigate} />
              <NewsPopupButton navigator={navigator} navigate={navigate} />
              <div
                className='bg-gray-400 w-32 h-10 rounded-lg
                flex justify-center items-center cursor-pointer'
                onClick={() => logout({ returnTo: window.origin })}
              >
                Log out
              </div>
            </div>
          </Drawer>
          <Menu isMobile notifications={notifications} setMessages={setMessages}>
            <div
              className='bg-orange-500 px-3 py-2 rounded-lg
              flex justify-center items-center cursor-pointer'
              onClick={() => {
                if (navigator == 'Lobbies') {
                  navigate('Notifications')
                } else if (navigator == 'Notifications') {
                  navigate('Lobbies')
                }
              }}
            >
              {navigator != 'Notifications' ? 'Notifications' : 'Ocultar'}
            </div>
            <div className='flex items-center mt-2'>
              <button
                onClick={() => setOpenDrawer(true)}
                data-collapse-toggle='navbar-default'
                type='button'
                className='inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden focus:outline-none focus:ring-gray-200'
                aria-controls='navbar-default'
                aria-expanded='false'
              >
                <svg
                  className='w-8 h-8'
                  aria-hidden='true'
                  fill='#d65944'
                  viewBox='0 0 20 20'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    fillRule='evenodd'
                    d='M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z'
                    clipRule='evenodd'
                  ></path>
                </svg>
              </button>
            </div>
          </Menu>
          <div className='w-full mt-3 flex flex-col'>
            {/* {
                navigator == 'Lobbies' ?

                  :

              } */}
            <div hidden={navigator != 'Lobbies'}>
              <Lobbies selectedLobby={selectedLobby} setSelectedLobby={setSelectedLobby} />
            </div>
            <div hidden={navigator != 'Notifications'}>
              <Notifications notifications={notifications} />
            </div>
            {(navigator == 'StatisticsDemand' || navigator == 'Departments') && (
              <StatisticsDemand navigate={navigate} navigator={navigator} />
            )}
            {navigator == 'StatisticsSupply' && (
              <StatisticsSupply navigate={navigate} navigator={navigator} />
            )}
            {navigator == 'Departments' && <DepartmentTable navigate={navigate} />}
            {navigator == 'UserSearch' && <UserSearch navigate={navigate} />}
            {navigator == 'DriverPayments' && <DriverPayments navigate={navigate} />}
            {navigator == 'ChatBroadcast' && <ChatBroadcast navigate={navigate} />}
            {navigator == 'NewsPopup' && <NewsPopup navigate={navigate} />}
          </div>
        </>
      ) : (
        <>
          {!loading ? (
            <>
              <Menu notifications={notifications} isMobile={isMobile} setMessages={setMessages}>
                <StatisticsButton
                  navigator={navigator}
                  navigate={navigate}
                  component='StatisticsDemand'
                  name='Demanda'
                />
                <StatisticsButton
                  navigator={navigator}
                  navigate={navigate}
                  component='StatisticsSupply'
                  name='Supply'
                />
                <UserSearchButton navigator={navigator} navigate={navigate} />
                <DriverPaymentsButton navigator={navigator} navigate={navigate} />
                <ChatBroadcastButton navigator={navigator} navigate={navigate} />
                <NewsPopupButton navigator={navigator} navigate={navigate} />
              </Menu>
              <div className='w-full mt-3 flex'>
                <Lobbies
                  selectedLobby={selectedLobby}
                  setSelectedLobby={setSelectedLobby}
                  hidden={navigator != 'Lobbies'}
                />
                <Notifications notifications={notifications} hidden={navigator != 'Lobbies'} />
              </div>
              {(navigator == 'StatisticsDemand' || navigator == 'Departments') && (
                <StatisticsDemand navigate={navigate} navigator={navigator} />
              )}
              {navigator == 'StatisticsSupply' && (
                <StatisticsSupply navigate={navigate} navigator={navigator} />
              )}
              {navigator == 'Departments' && <DepartmentTable navigate={navigate} />}
              {navigator == 'UserSearch' && <UserSearch navigate={navigate} />}
              {navigator == 'DriverPayments' && <DriverPayments navigate={navigate} />}
              {navigator == 'ChatBroadcast' && <ChatBroadcast navigate={navigate} />}
              {navigator == 'NewsPopup' && <NewsPopup navigate={navigate} />}
            </>
          ) : (
            <div>Cargando...</div>
          )}
        </>
      )}
      {messages && <Messages visible={messages} setVisible={setMessages} />}
    </>
  )
}

const StatisticsButton = ({ navigator, navigate, component, name }) => (
  <div
    className=' bg-yellow-500 w-32 h-10 rounded-lg 
flex justify-center items-center cursor-pointer'
    onClick={() => {
      if (navigator == component || navigator == 'Departments') {
        navigate('Lobbies')
      } else {
        navigate(component)
      }
    }}
  >
    {navigator == component || navigator == 'Departments' ? 'Ocultar' : name}
  </div>
)

const UserSearchButton = ({ navigator, navigate }) => (
  <div
    className='bg-blue-500 w-32 h-10 rounded-lg
  flex justify-center items-center cursor-pointer'
    onClick={() => {
      if (navigator == 'UserSearch') {
        navigate('Lobbies')
      } else {
        navigate('UserSearch')
      }
    }}
  >
    {navigator == 'UserSearch' ? 'Ocultar' : 'Buscar usuario'}
  </div>
)

const DriverPaymentsButton = ({ navigator, navigate }) => (
  <div
    className='bg-blue-500 px-2 h-10 rounded-lg
flex justify-center items-center cursor-pointer'
    onClick={() => {
      if (navigator == 'DriverPayments') {
        navigate('Lobbies')
      } else {
        navigate('DriverPayments')
      }
    }}
  >
    {navigator == 'DriverPayments' ? 'Ocultar' : 'Pagos pendientes'}
  </div>
)

const ChatBroadcastButton = ({ navigator, navigate }) => (
  <div
    className='bg-blue-500 px-2 h-10 rounded-lg
flex justify-center items-center cursor-pointer'
    onClick={() => {
      if (navigator == 'ChatBroadcast') {
        navigate('Lobbies')
      } else {
        navigate('ChatBroadcast')
      }
    }}
  >
    {navigator == 'ChatBroadcast' ? 'Ocultar' : 'Chat Broadcast'}
  </div>
)

const NewsPopupButton = ({ navigator, navigate }) => (
  <div
    className='bg-blue-500 px-2 h-10 rounded-lg
flex justify-center items-center cursor-pointer'
    onClick={() => {
      if (navigator == 'NewsPopup') {
        navigate('Lobbies')
      } else {
        navigate('NewsPopup')
      }
    }}
  >
    {navigator == 'NewsPopup' ? 'Ocultar' : 'Popup'}
  </div>
)

export default App
